<html 
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex">
        <div class="d-flex-column flex-1">
            <div class="d-flex-column flex-2" style="margin-top:10px; margin-bottom:20px;">
                <div class="d-flex flex-2 m-l-254">
                    <div class="b-logo w-50" [ngStyle]="{
                                'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                                'var(--b-image-url)' 
                            }"></div>
                </div>
            </div>
            <div class="d-flex-column flex-7">
                <div class="flex-2 b-q-logo m-l-254 m-r-254" [ngStyle]="{
                            'background-image': selectedCoverImage?.uniqueFileName ? 'url(' + selectedCoverImage?.imgSrc + ')' :
                            'var(--b-q-image-url)' 
                        }"></div>
            </div>
            <div class="d-flex-column flex-1 m-l-254 m-r-254 d-flex flex-column align-items-start"
                style="padding: 5mm 0mm; font-family: qc-Montserrat-ExtraBold !important;" 
                [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}">
                <div class="v-center" style="padding-top: 6mm;">
                    <div style="font-size: 11mm; line-height: 1.42; -webkit-box-orient: vertical; display: -webkit-box; max-width: 100%; height: 125px; overflow-wrap: break-word; -webkit-line-clamp: 2; text-overflow: ellipsis; overflow: hidden; box-sizing: border-box; word-break: break-word;">{{ templateInfo?.description1 }}</div>
                </div>
            </div>
            <div class="flex-row flex-2 m-l-254 m-r-254 d-flex align-items-start" style="padding: 5mm 0mm; color:black;">
                <div class="d-flex-column flex-2">
                    <div class="d-flex-row" style="padding-bottom: 2mm;">
                        <span class="flex-10" style="font-size: 6mm; font-family: qc-Montserrat-ExtraBold; text-align: left;">Prepared
                            for:</span>
                    </div>
                    <div style="font-size: 5mm; font-family: qc-OpenSans-Regular;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                        Client Name
                    </div>
                    <div style="font-size: 5mm; font-family: qc-OpenSans-Regular;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                        Address
                    </div>
                    <div style="font-size: 5mm; font-family: qc-OpenSans-Regular;" class="d-flex flex-column align-items-start address-ellipsis">
                        City, State, Zip Code
                    </div>
                </div>
                <div class="d-flex-column flex-2" style="text-align:left;">
                    <div class="d-flex-row" style="padding-bottom: 2mm;">
                        <span class="flex-10" style="font-size: 6mm; font-family: qc-Montserrat-ExtraBold;">Presented
                            by:</span>
                    </div>
                    <div style="font-size: 5mm; font-family: qc-OpenSans-Regular;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                        {{ templateInfo?.userName }}
                    </div>
                    <div style="font-size: 5mm; font-family: qc-OpenSans-Regular;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                        {{ templateInfo?.usePhoneNumer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>
</body>

</html>