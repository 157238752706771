import { Component, EventEmitter, Output } from '@angular/core';
import { CloudinaryCoverUploadSignal, TemplateSignal } from '@app/main/quotes/models';
import { AppConsts } from '@shared/AppConsts';
import { CloudinaryMediaLibraryComponent } from '../cloudinary-media-library/cloudinary-media-library.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent {
  allowedFileTypes = ['.jpg', '.jpeg', '.png'];
  // @Output() signal = new EventEmitter<TemplateSignal>();

  @Output() signal = new EventEmitter<CloudinaryCoverUploadSignal>();


  showUpload: boolean = false;
  myWidget: any;

  constructor(private _dialogService: DialogService) {
  }

  ngOnInit() {
    this.myWidget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: AppConsts.cloudName,
        uploadPreset: AppConsts.uploadPreset,
        sources: ["local", "url", "camera", "image_search", "google_drive"],
        multiple: false,
        folder: "user_images",
        clientAllowedFormats: ["image"],
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          const img:CloudinaryCoverUploadSignal = {
            imageId: result.info.id,
            url: result.info.secure_url,
            fileUniqueName: result.info.original_filename,
          }
          this.signal.emit(img);

        }
      }
    );
  }

  onUploadFromComputer(): void {

  }
  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    // this.signal.emit({ key: 'upload', value: file });
  }

  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }


  fileBrowseHandler(target: HTMLInputElement): void {
    const file = target.files.item(0);
    // this.signal.emit({ key: 'upload', value: file });
  }

  openWidget() {
    this.myWidget.open();
  }

  openMediaLibraryDialog(){
    const dialog = this._dialogService.open(CloudinaryMediaLibraryComponent, {
      showHeader: true,
      header:"Media Library",
      width: '70vw',
      height: '100vh',
      styleClass: 'media-library-dlg',
      data: 1,
  });

  dialog.onClose.subscribe((file: any) => {
      if (file) 
      {
          
          const img:CloudinaryCoverUploadSignal = {
              imageId: (file && file.assets && file.assets[0] && file.assets[0].id) || file?.id,
              fileUniqueName: (file && file.assets && file.assets[0] && file.assets[0].display_name) || file?.display_name,
              url: (file && file.assets && file.assets[0] && file.assets[0].secure_url) || file?.secure_url
          };

          this.signal.emit(img);
      }
  });
  }
}
