import { Directive, Renderer2, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[ngxImgSpinner]',
  exportAs: 'ngxImgSpinner'
})
export class ImgLoadingDirective {
  private _loading = true;
  private _error = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef) {
  }

  get error(): boolean { return this._error; }

  get loading(): boolean { return this._loading; }
  set loading(val: boolean) {
    if (val)
      this.renderer.addClass(this.el.nativeElement, 'hidden');
    else
      this.renderer.removeClass(this.el.nativeElement, 'hidden');
    this._loading = val;
  }

  @HostListener('loadstart') onLoadStart() {
    this.loading = true;
  }

  @HostListener('load') onLoad() {
    this.loading = false;
    this.renderer.addClass(this.el.nativeElement, 'show');
  }
  @HostListener('error') onError() {
    this.loading = false;
    this._error = true;
    this.renderer.addClass(this.el.nativeElement, 'error');
  }

}