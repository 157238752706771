<a href="/" [class]="customHrefClass" (window:resize)="onResize()">
    <img
        alt="Logo"
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        [src]="defaultLogo"
        class="logo-img app-sidebar-logo-default"
    />
    <img
        alt="Logo"
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            this.skin +
            '&tenantId=' +
            appSession.tenant.id
        "
        class="logo-img app-sidebar-logo-default"
    />
    <img
        alt="Logo"
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            this.skin +
            '&tenantId=' +
            appSession.tenant.id
        "
        class="logo-img app-sidebar-logo-default"
    />
    <img
        alt="Logo"
        *ngIf="appSession.tenant && appSession.tenant.HasLogoMinimal()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            this.skin + '-sm' +
            '&tenantId=' +
            appSession.tenant.id
        "
        class="logo-img app-sidebar-logo-minimize"
    />
</a>
<style>
    .logo-img {
        vertical-align: middle;
        border-style: none;
        width: 100px;
        height: auto;
    }
</style>