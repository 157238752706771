import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { Angular2CountoModule } from '@awaismirza/angular2-counto';
import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { CompanyQuoteCommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import {
    BsDatepickerConfig,
    BsDatepickerModule,
    BsDaterangepickerConfig,
    BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';

import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { TemplateFooterComponent } from './business-templates/template-footer/template-footer.component';
import { Template1Component } from './business-templates/template1/template1.component';
import { Template2Component } from './business-templates/template2/template2.component';
import { Template3Component } from './business-templates/template3/template3.component';
import { Template4Component } from './business-templates/template4/template4.component';
import { Template5Component } from './business-templates/template5/template5.component';
import { Template6Component } from './business-templates/template6/template6.component';
import { Template7Component } from './business-templates/template7/template7.component';
import { Template8Component } from './business-templates/template8/template8.component';
import { Template9Component } from './business-templates/template9/template9.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { TemplateOneModule } from './section-type-templates-1/section-type-templates-1.module';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { CloudinaryMediaLibraryComponent } from './cloudinary-media-library/cloudinary-media-library.component';
import { UploadImagesComponent } from './upload-images/upload-images.component';
import { CloudinaryServiceProxy } from '@shared/service-proxies/service-proxies';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RouterModule } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MediaLibraryComponent, SafeUrlPipe } from './media-library/media-library.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { SvgIconComponent } from 'assets/icons/svg-icon/svg-icon.component';
import { PreviewV2Component } from '@app/preview/preview-v2/preview-v2.component';
import { CdkDrag, CdkDropList, CdkDropListGroup, DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadService } from '../services/file-pload.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        CompanyQuoteCommonModule,
        TableModule,
        PaginatorModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        Angular2CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        SignaturePadComponent,
        TemplateFooterComponent,
        TemplateOneModule,
        ProgressSpinnerModule,
        RouterModule,
        DynamicDialogModule,
        MultiSelectModule,
        TabViewModule,
        FileUploadModule,
        FileUploadModule, ToastModule,MenuModule,CheckboxModule,DragDropModule,PdfViewerModule,
        ListboxModule,CdkDropListGroup, CdkDropList, CdkDrag,
        NgxDaterangepickerMd.forRoot(),
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        Template1Component,
        Template2Component,
        Template3Component,
        Template4Component,
        Template5Component,
        Template6Component,
        Template7Component,
        Template8Component,
        Template9Component,
        CloudinaryMediaLibraryComponent,
        UploadImagesComponent,
        MediaLibraryComponent,
        SafeUrlPipe
        // new section templates
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        Template1Component,
        Template2Component,
        Template3Component,
        Template4Component,
        Template5Component,
        Template6Component,
        Template7Component,
        Template8Component,
        Template9Component,
        SignaturePadComponent,
        TemplateOneModule,
        UploadImagesComponent,
        

    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        CloudinaryServiceProxy,
        DynamicDialogConfig,
        MessageService,FileUploadService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
    ],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
