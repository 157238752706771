import { Injectable, Inject} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// rxjs
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  private http: HttpClient;
  private baseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
    this.baseUrl = environment.remoteServiceBaseUrl;
  }

  createClient(payload) {
    let url_ = this.baseUrl + '/api/services/app/ClientService/CreateClient';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.post(url_, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateClient(payload) {
    let url_ = this.baseUrl + '/api/services/app/ClientService/UpdateClient';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.put(url_, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteClient(id: number) {
    let url_ = this.baseUrl + '/api/services/app/ClientService/DeleteClient';
    url_ = url_.replace(/[?&]$/, '');

    let params = new HttpParams();
    params = params.append('Id', id);

    return this.http.delete(url_, { params }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getClientsWithFilters(payload) {
    let url_ = this.baseUrl + '/api/services/app/ClientService/GetAllClient';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.post(url_, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getClientsAll(pageOptions, filters?) {
    let url_ = this.baseUrl + '/api/services/app/ClientService/GetAllClient';
    url_ = url_.replace(/[?&]$/, '');

    let params = new HttpParams();
    params = params.append('page', pageOptions?.currentPage);
    params = params.append('ItemPerPage', pageOptions?.itemsPerPage);

    if (filters?.searchValue) {
      params = params.append('filter', filters?.searchValue);
    }

    if (filters.sorting) {
      params = params.append('sorting', filters?.sorting);
    }

    return this.http
      .get(url_, { params })
      .pipe(
        map((response: any) => response)
      );
  }

  getClientsToExcel(filters?) {
    let url_ = this.baseUrl + '/api/services/app/ClientService/GetClientsToExcel';
    url_ = url_.replace(/[?&]$/, '');

    let params = new HttpParams();
    
    if (filters?.searchValue) {
      params = params.append('Filter', filters?.searchValue);
    }

    if (filters.sorting) {
      params = params.append('Sorting', filters?.sorting);
    }

    return this.http
      .get(url_, { params })
      .pipe(
        map((response: any) => response)
      );
  }
}
