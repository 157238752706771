<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">

<head>
    <style>
        body {
            font-family: 'qcfont';
            position: relative;
        }

        .bullet {
            background: var(--bs-primary);
            max-height: 12mm;
            max-width: 12mm;
            border-radius: 100%;
            font-size: 2.5rem;
        }

        .footer {
            padding: 4mm 0;
            justify-content: space-around;
            font-size: 7mm;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        .cover {
            position: absolute;
            top: 70mm;
            left: 51mm;
            right: 0mm;
            height: 103mm;
        }

        .pencil1 {
            position: absolute;
            background: var(--bs-primary);
            left: 0;
            width: 52mm;
            height: 75mm;
            top: 44mm;
        }

        .pencil1::before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            border-top: 56mm solid white;
            border-bottom: 0 solid transparent;
            border-left: 54mm solid transparent;
            top: -1mm;
            left: -1mm;
        }

        .pencil1::after {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            border-top: 35mm solid transparent;
            border-bottom: 0 solid transparent;
            border-left: 33mm solid white;
            bottom: -1mm;
            left: -1mm;
        }

        .pencil2 {
            position: absolute;
            background: var(--bs-primary);
            left: 0;
            top: 123mm;
            width: 52mm;
            height: 75mm;
        }

        .pencil2::before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            border-top: 56mm solid transparent;
            border-bottom: 0 solid transparent;
            border-right: 54mm solid white;
            bottom: -1mm;
            right: -1mm;
        }

        .pencil2::after {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            border-top: 35mm solid white;
            border-bottom: 0 solid transparent;
            border-right: 33mm solid transparent;
            top: -1mm;
            left: -1mm;
        }
    </style>


</head>

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex">
        <div class="flex-1 d-flex-column">
            <div class="d-flex-row flex-7"
                [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}">
                <div class="d-flex-column flex-1">
                    <div class="d-flex flex-2 m-l-254">
                    </div>
                    <div class="d-flex flex-4">
                        <div class="pencil1"
                        [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></div>
                        <div class="pencil2"
                        [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></div>
                    </div>
                    <div class="d-flex-column flex-2">
                        <div style="min-height: 50mm; margin-left: 4cm;">
                            <div style="font-size: 7mm; font-weight: bold; padding-bottom: 2mm; text-align: left;">
                                Prepared for:
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                Client Name
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                Address
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="address-ellipsis">
                                City, State, Zip Code
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex-column flex-1">
                    <div class="d-flex flex-2">
                        <div class="b-logo w-100" [ngStyle]="{
                            'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                            'var(--b-image-url)', 
                            'background-position': 'center',
                            'background-size': 'contain'
                        }"></div>
                    </div>
                    <div class="d-flex flex-4">
                    </div>
                    <div class="d-flex-column flex-2" style="text-align:left; margin-left: 30px; margin-right: 4cm;"
                        [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}">
                        <div style="min-height: 50mm;">
                            <div style="font-size: 7mm; font-weight: bold; padding-bottom: 2mm;">
                                Presented by:
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                {{ templateInfo?.userName }}
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                {{ templateInfo?.usePhoneNumer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex cover">
                <div class="title-ellipsis"
                    style="font-size: 20mm; padding: 20mm 7px 15mm 15mm; line-height: 1.5; text-align: left; font-family: qc-RussoOne-Regular;"
                    [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}"
                    >
                    {{ templateInfo?.description1 }}</div>
            </div>

        </div>
    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>
</body>

</html>