import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class TemplateFooterComponent {
  @Input() templateInfo;
  @Input() transparentBackground = false;
}
