import { Injector, Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './theme60-brand.component.html',
    selector: 'theme60-brand',
    encapsulation: ViewEncapsulation.None,
})
export class Theme60BrandComponent extends AppComponentBase {
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(injector: Injector, @Inject(DOCUMENT) private document: Document) {
        super(injector);
    }

    getMenuSkin(): string {
        return this.appSession.theme.baseSettings.layout.darkMode || this.appSession.theme.baseSettings.menu.asideSkin == "dark" ? "dark" : "light";
    }
}
