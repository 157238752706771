import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneratePdfService } from '@app/shared/services/generate-pdf.service';
import { QuoteImageService } from '@app/shared/services/quote-image.service';
import { IQuoteSingOutputDto } from '@shared/service-proxies/service-proxies';
import saveAs from 'file-saver';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'app-sign-quote-form',
    templateUrl: './sign-quote-form.component.html',
    styleUrls: ['./sign-quote-form.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ProgressSpinnerModule],
})
export class SignQuoteFormComponent implements OnInit {
    key: string;
    signedByFC = new FormControl('', Validators.required);
    disableDownloadQuoteBtn = false;
    disableSignQuoteBtn = false;
    quoteSignatureData: IQuoteSingOutputDto;
    loading: boolean = true;
    quoteId:any;
    isChanged: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private _pdfService: GeneratePdfService,
        private _quoteImageService: QuoteImageService,

        private _dialogConfig: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params: Params) => {
            this.key = params['siteKey'];
        });
        const { quoteSignatureData,quoteId } = this._dialogConfig.data;

        this.quoteSignatureData = quoteSignatureData;
        this.quoteId  = quoteId;

        this.loading = false;
    }

    onSignQuote(): void {
        this.loading = true;
        this.disableSignQuoteBtn = true;
        const file = this.getSignatureFileData();

        const payload = new FormData();
        payload.append('File', file);
        payload.append('key', this.key);
        payload.append('SignedNameClient', this.signedByFC.value);

        this._pdfService.uploadQuoteImageFileForSignClient(payload).subscribe({
            next: () => {
                this.quoteSignatureData.isSignedFromClient = true;
                this.quoteSignatureData.signedNameClient = this.signedByFC.value;
                this.loading = false;
                this.getPdfUrl();
            },
            error: () => (this.disableSignQuoteBtn = false),
        });
    }

    async onDownloadQuote() {
        this.disableDownloadQuoteBtn = true;
        this._pdfService.generateQuotePdf(this.quoteId, this.isChanged).subscribe({
            next: (resp: Blob) => {
                const downloadURL = window.URL.createObjectURL(resp);
                saveAs(downloadURL, 'Quote.pdf');
                this.disableDownloadQuoteBtn = false;
            },
            error: () => (this.disableDownloadQuoteBtn = false),
        });
    }

    private getSignatureFileData(): File {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.font = "bold 40px Bradley Hand ITC";
        ctx.fillStyle = "#000000";
        ctx.fillText(this.signedByFC.value, 30, 80, 200);


        const image = canvas.toDataURL('image/png');
        const file = this.dataURLtoFile(image, 'signature.jpg');
        return file;
    }

    private dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    getPdfUrl(): void {
        this._pdfService.generateQuotePdf(this.quoteId, this.isChanged).subscribe({
            next: (resp: any) => {
            },
        });
    }
}
