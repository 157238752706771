<!--  Free Text Template Starts  -->
<ng-container [ngSwitch]="isTemplateType">

    <!-- Static Template Starts -->
    <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
        <div class="container-fluid main-page shadow mb-8">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-column">
                            <h1 class="template-title">INSPECTION REPORT</h1>
                            <!-- <span class="template-title-border"></span> -->
                        </div>
                    </div>
                    <div class="body-layout body-free-text" [innerHTML]="text"></div>
                    <!-- <div class="template-footer">
                            <span class="template-footer-page-no">2</span>
                            <span class="template-footer-company">Roof Company</span>
                        </div> -->
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
        <div class="container-fluid main-page shadow next-page">
            <div class="row">
                <div class="col-12">
                    <div class="body-layout body-free-text" [innerHTML]="textOne"></div>
                    <!-- <div class="template-footer">
                            <span class="template-footer-page-no">2</span>
                            <span class="template-footer-company">Roof Company</span>
                        </div> -->
                </div>
            </div>
        </div>
    </ng-container>
    <!--  Static Template Ends  -->

    <!--  Preview Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
        <div class="container-fluid bg-white main-page p-12 shadow">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-column">
                            <h1 class="template-title" [ngStyle]="{ color: templateInfo?.templateColor ? templateInfo?.templateColor : '' }">{{ data ? data.name : '-' }}</h1>
                            <!-- <span class="template-title-border"></span> -->
                        </div>
                    </div>
                    <div class="body-layout body-free-text" [innerHTML]="data && data.jsonValue ? data.jsonValue.Content.Html : ''"></div>
                    <!-- <div class="template-footer">
                            <span class="template-footer-page-no">{{ data ? data.index : '2' }}</span>
                            <span class="template-footer-company">Roof Company</span>
                        </div> -->
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Preview Template Ends -->

</ng-container>
<!--  Free Text Template Ends  -->