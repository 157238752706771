<div class="main-content" *ngIf="!loading; else loadingTemp">
    <section class="quate-box" *ngIf="quoteSignatureData?.isSignedFromClient; else uploadSignatureImage">
        <div class="is-signed">
            <div class="done-circle">
                <i class="bi bi-check"></i>
            </div>

            <div class="signature">
                <label>
                    Signed By:
                    <span>{{ quoteSignatureData?.signedNameClient }}</span>
                </label>
                <div class="signature__date">
                    {{ quoteSignatureData.signatureDate + '' | date : 'dd/MM/YYYY' }}
                </div>
            </div>
        </div>

        <div class="actions">
            <button [disabled]="disableDownloadQuoteBtn" class="btn btn-outline-light" (click)="onDownloadQuote()">
                Download Signed Quote
                <p-progressSpinner
                    *ngIf="disableDownloadQuoteBtn"
                    class="progress-spinner-height ms-3"
                    strokeWidth="4"
                ></p-progressSpinner>
            </button>
        </div>
    </section>

    <ng-template #uploadSignatureImage>
        <div class="quate-box">
            <div class="title">Sign the quote by entering your full name in the field below</div>

            <div class="form">
                <div class="form-group">
                    <!-- <label class="signed-by-label" for="SignedBy">Signed by</label> -->
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Type your Full Name"
                        [formControl]="signedByFC"
                    />
                </div>
            </div>

            <div class="actions">
                <button
                    [disabled]="signedByFC.invalid || disableSignQuoteBtn"
                    class="btn btn-outline-primary"
                    (click)="onSignQuote()"
                >
                    Accept & Sign Quote
                    <p-progressSpinner
                        *ngIf="disableSignQuoteBtn"
                        class="progress-spinner-height ms-3"
                        strokeWidth="4"
                    ></p-progressSpinner>
                </button>

                <button [disabled]="disableDownloadQuoteBtn" class="btn btn-outline-light" (click)="onDownloadQuote()">
                    Download Unsigned Quote
                    <p-progressSpinner
                        *ngIf="disableDownloadQuoteBtn"
                        class="progress-spinner-height ms-3"
                        strokeWidth="4"
                    ></p-progressSpinner>
                </button>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #loadingTemp>
    <div class="main-content">
        <div class="d-flex justify-content-center">
            <p-progressSpinner class="progress-spinner-height" strokeWidth="4"></p-progressSpinner>
        </div>
    </div>
</ng-template>
