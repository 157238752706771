import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IApprovalTemplate, IQuotePage, TemplateViewTypesEnum } from '@app/main/quotes/models';
import { QuoteServiceServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-signature-new',
    templateUrl: './signature-new.component.html',
    styleUrls: ['./signature-new.component.scss'],
})
export class SignatureNewComponent implements OnInit {
    @Input() isTemplateType: TemplateViewTypesEnum = TemplateViewTypesEnum.StaticTemplate;
    templateTypes = TemplateViewTypesEnum;
    @Input() templateInfo;

    @Input() quoteId: string;
    @Input() isLoading: boolean = true;
    @Input() data: IQuotePage;
    @Input() pageNo: number;
    quoteSignatureImage: string;
    signedImageNameClient: string;
    signedNameClient: string;
    signatureDate: Date;

    dataItem: IQuotePage;
    item: IApprovalTemplate;

    constructor(private _quoteService: QuoteServiceServiceProxy, private route: ActivatedRoute) {}

    ngOnInit(): void {
        if (this.quoteId) {
            this.getQuoteByIdIncludesTbl();
        }
    }

    private getQuoteByIdIncludesTbl(): void {
        this._quoteService.getQuoteByIdIncludesTbl(this.quoteId).subscribe({
            next: (resp: any) => {
                const { signatureImageNamePath, signedImageNameClientPath, signedNameClient, signatureDate } = resp;

                if (signatureImageNamePath.includes('.')) {
                    this.quoteSignatureImage = signatureImageNamePath;
                }
                if (signedImageNameClientPath.includes('.')) { 
                    this.signedImageNameClient = signedImageNameClientPath;
                    this.signedNameClient = signedNameClient;
                    this.signatureDate = signatureDate;
                }
            },
        });
    }

    ngOnDestroy(): void {}
}
