import { Pipe, PipeTransform } from '@angular/core';

type DateTime = string | Date;

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform {

  transform(value: DateTime): string {
    if (!value) return '';

    let date: Date;
    if (typeof value === 'string') {
      date = new Date(value);
    } else {
      date = value;
    }

    if (isNaN(date.getTime())) return '';

    // Options for formatting the time in GMT (UTC)
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC', // Specify UTC to get GMT time
    };

    // Get the browser's locale
    const locale = navigator.language;

    // Format the time in GMT (UTC)
    return new Intl.DateTimeFormat(locale, options).format(date);
  }
}
