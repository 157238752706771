import { Component, EventEmitter, Output, ViewChild, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// services
import { ClientsService } from '@app/shared/services/clients.service';

// components
import { AppComponentBase } from '@shared/common/app-component-base';

// libraries
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-client-creation',
  templateUrl: './client-creation.component.html',
  styleUrls: ['./client-creation.component.css']
})
export class ClientCreationComponent extends AppComponentBase implements OnInit {
  @ViewChild('clientCreationModal', { static: true }) modal: ModalDirective;

  @Output() onModalSave: EventEmitter<any> = new EventEmitter<any>();

  clientForm: FormGroup;
  client: any;

  // loaders
  saving = false;

  isEditMode = false;
  submitAttempt = false;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private clientService: ClientsService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: [''],
      email: ['', Validators.required],
      // description: ['', Validators.required],
      address1: ['', Validators.required],
      // address2: ['', Validators.required],
      city: [''],
      state: [''],
      zip: [''], 
    });
  }

  saveClient() {
    this.submitAttempt = true;

    if (!this.clientForm.valid) {
      return;
    }

    this.saving = true;

    const payload = {
      ...this.clientForm.value,
      ...(this.isEditMode && { id: this.client?.id })
    };


    if (!this.isEditMode) {
      this.clientService.createClient(payload).subscribe(
        response => {
          this.saving = false;
          this.notify.info(this.l('SavedSuccessfully'));
          this.onModalSave.emit(response?.result?.id);
          this.closeModal();
        }, error => {
          this.saving = false;
          this.notify.error(`Error: ${error?.message}`);
        }
      );
    } else {
      this.clientService.updateClient(payload).subscribe(
        response => {
          this.saving = false;
          this.notify.info(this.l('Updated successfully'));
          this.onModalSave.emit(response?.result?.id);
          this.closeModal();
        }, error => {
          this.saving = false;
          this.notify.error(`Error: ${error?.message}`);
        }
      );
    }
  }

  showModal(client?: any): void {
    this.clientForm.reset();
    this.submitAttempt = false;

    if (client) {
      this.isEditMode = true;
      this.client = client;

      this.clientForm.patchValue(client);
    } else {
      this.isEditMode = false;
    }

    this.modal.show();
  }

  onFocus(): void {
    document.getElementById('clientName').focus();
  }

  closeModal(): void {
    this.modal.hide();
  }
}
