<!--  Image Text List Template Starts  -->
<ng-container [ngSwitch]="isTemplateType">

    <!--  Static Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
        <div class="container-fluid main-page shadow 1">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-column">
                            <h1 class="template-title"><span>Inspection report</span></h1>
                        </div>
                    </div>
                    <div class="body-layout">
                        <h3 class="section-title text-truncate">Lack of ventilation</h3>
                        <div class="row main-div">
                            <div class="col-6 col-md-6 image-div">
                                <img class="img-fluid" src="../../../../../assets/media/templates/report-img-one.png" alt="report-img-one" />
                            </div>
                            <div class="col-6 col-md-6 text-div">
                                <p class="custom-text">Upon inspection of the roofing structure, it is evident that there is a significant lack of ventilation. Proper ventilation is crucial to the longevity and efficiency of a roofing system as it regulates temperature and moisture levels in the attic space.</p>
                                <p class="custom-text">The current condition can lead to a buildup of heat and humidity, which may result in damage to the roof deck, shingles, and insulation, and can potentially decrease the overall energy efficiency of the home.</p>
                            </div>
                        </div>
                        <h3 class="section-title text-truncate">Lack of ventilation</h3>
                        <div class="row main-div">
                            <div class="col-6 col-md-6 image-div">
                                <img class="img-fluid" src="../../../../../assets/media/templates/report-img-two.png" alt="report-img-two" />
                            </div>
                            <div class="col-6 col-md-6 text-div">
                                <p class="custom-text">Upon inspection of the roofing structure, it is evident that there is a significant lack of ventilation. Proper ventilation is crucial to the longevity and efficiency of a roofing system as it regulates temperature and moisture levels in the attic space.</p>
                                <p class="custom-text">The current condition can lead to a buildup of heat and humidity, which may result in damage to the roof deck, shingles, and insulation, and can potentially decrease the overall energy efficiency of the home.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--  Static Template Ends  -->

    <!--  Preview Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
        <div class="container-fluid main-page" [ngClass]=" i !== 1 ? ' ' : 'mt-8'" *ngFor="let reportSlicedObj of result let i =index">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-column">
                            <h1 class="template-title"><span>{{ data ? data.name : '-' }}</span></h1>

                            <!-- <h1 class="template-title"></h1> -->
                        </div>
                    </div>
                    <div class="body-layout" *ngIf="data && data.jsonValue">
                        <div *ngFor="let report of reportSlicedObj">
                            <h3 class="section-title text-truncate">{{ report.content.title }}</h3>
                            <div class="row main-div">
                                <div class="col-6 col-md-6 image-div">
                                    <img src="{{ report.Image.image || report.Image.url}}" alt="Report image" />
                                </div>
                                <div class="col-6 col-md-6 text-div desc-content" [innerHTML]="report.content.Html"></div>
                            </div>
                        </div>
                        <!-- <div *ngFor="let report of [1,2]">
                            <h3 class="section-title text-truncate">Title of Section {{report}}</h3>
                            <div class="row main-div">
                                <div class="col-6 col-md-6 image-div">
                                    <img src="https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg" alt="Report image" />
                                </div>
                                <div class="col-6 col-md-6 text-div" >
                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                                </div>
                            </div>
                        </div> -->

                    </div>

                </div>
            </div>
        </div>
    </ng-container>
    <!--  Preview Template Ends  -->

</ng-container>
<!--  Image Text List Template Ends  -->
