<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">

<head>
    <style>
        body {
            position: relative;
        }

        * {
            box-sizing: content-box !important;
        }
    </style>
</head>

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex p-0">
        <div class="flex-1 d-flex d-flex-column text-secondary">
            <div class="d-flex-column flex-10">

                <div class="d-flex d-flex-row flex-1">
                    <div class="flex-2 b-logo p-l-254" [ngStyle]="{
                    'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                    'var(--b-image-url)',
                    'background-position': 'center',
                    'border-top-left-radius': '20px'
                }"></div>
                    <div class="flex-3"></div>
                </div>

                <div class="d-flex d-flex-row flex-1 m-l-254"
                    style="font-size: 15mm; justify-content: space-evenly; padding-bottom: 6mm; color: #000000;">
                    <div class="flex-1 d-flex flex-column align-items-start">
                        <div class="d-flex-row" style="font-size: 7mm;margin-bottom: 3mm; font-weight: bold;">
                            Prepared for:
                        </div>
                        <div style="font-size: 5mm;" class="paragpraph-ellipsis">
                            Client Name
                        </div>
                        <div style="font-size: 5mm;" class="paragpraph-ellipsis">
                            Address
                        </div>
                        <div style="font-size: 5mm;" class="address-ellipsis">
                            City, State, Zip Code
                        </div>
                    </div>
                </div>
                <div class="d-flex d-flex-row flex-1 m-l-254"
                    style="font-size: 15mm; justify-content: space-evenly; padding-bottom: 6mm; color: #000000;">
                    <div class="flex-1 d-flex flex-column align-items-start">
                        <div class="d-flex-row" style="font-size: 7mm; margin-bottom: 3mm; font-weight: bold;">
                            Presented by:
                        </div>
                        <div style="font-size: 5mm;" class="paragpraph-ellipsis">
                            {{ templateInfo?.userName }}
                        </div>
                        <div style="font-size: 5mm;" class="paragpraph-ellipsis">
                            {{ templateInfo?.usePhoneNumer }}
                        </div>
                    </div>
                </div>
                <div class="d-flex d-flex-row flex-3 bg-secondary text-primary" style="position:relative">
                    <div class="flex-1 b-q-logo" [ngStyle]="{
                        'background-image': selectedCoverImage?.uniqueFileName ? 'url(' + selectedCoverImage?.imgSrc + ')' :
                        'var(--b-q-image-url)',
                        'background-size': 'cover'
                    }"></div>
                </div>
            </div>
            <div class="rightline"
                [ngStyle]="{'border-color': templateInfo?.templateColor ? '' + templateInfo?.templateColor + ' transparent transparent transparent' : ''}">
            </div>
            <div class="title text-secondary" style="width: 40%;">
                <div style="font-size: 7mm; font-weight: bold; padding-left: 5mm; font-family: qc-RussoOne-Regular; line-height: 1.42; -webkit-box-orient: vertical; display: -webkit-box; max-width: 100%; max-height: 115px; overflow-wrap: break-word; -webkit-line-clamp: 3; text-overflow: ellipsis; overflow: hidden; box-sizing: border-box; word-break: break-word;">
                    {{ templateInfo?.description1 }}</div>
            </div>
        </div>

    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>

</body>

</html>