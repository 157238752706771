import { Pipe, PipeTransform } from '@angular/core';
import { ITaxListDto } from '@shared/service-proxies/service-proxies';

@Pipe({
    name: 'TaxId',
})
export class TaxIdPipe implements PipeTransform {
    transform(taxes: ITaxListDto[], id: number): ITaxListDto {
        if (taxes) {
            return taxes.find((el) => el.id == id);
        }
        return null;
    }
}
