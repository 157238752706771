<div
    appBsModal
    #createOrEditEmailTemplateComponent="bs-modal"
    (onShown)="onFocus()"
    class="modal fade create-or-edit-user-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditEmailTemplateComponent"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: !saving }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="emailTemplateForm">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="emailTemplate?.id">{{ 'Edit Email template' | localize }}</span>
                        <span *ngIf="!emailTemplate?.id">{{ 'Create new email template' | localize }}</span>
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        (click)="closeModal()"
                        [attr.aria-label]="l('Close')"
                        [disabled]="saving"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="mb-5">
                        <label class="form-label" for="subject">{{ 'Subject' | localize }} *</label>
                        <input
                            id="subject"
                            formControlName="subject"
                            type="text"
                            name="subject"
                            class="form-control"
                            id="emailTemplateSubject"
                        />
                        <validation-messages [formCtrl]="emailTemplateForm.controls.subject"></validation-messages>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 fv-row">
                            <label class="form-label" for="body">{{ 'Body' | localize }} *</label>
                            <textarea
                                rows="4"
                                id="body"
                                formControlName="body"
                                name="body"
                                class="form-control"
                            ></textarea>
                            <validation-messages [formCtrl]="emailTemplateForm.controls.body"></validation-messages>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div class="mb-5 pe-2 w-100">

                            <label class="form-label" for="phone">{{ 'Type' | localize }}</label>
                            <select
                                class="form-control"
                                formControlName="type">
                                <option value="" disabled selected>Choose a type</option>
                                <option *ngFor="let type of types" [value]="type.name">
                                    {{ type.name }}
                                </option>
                            </select>
                            <!-- <p-dropdown
                                class="d-flex flex-fill full-width-dropdown"
                                [filter]="true"
                                filterBy="name"
                                [showClear]="true"
                                [options]="types"
                                [(ngModel)]="selectedType"
                                appendTo="body"
                                optionLabel="name"
                                placeholder="Choose a type"
                            ></p-dropdown> -->
                            <!-- <input
                                id="type"
                                formControlName="type"
                                type="text"
                                name="type"
                                class="form-control"
                            /> -->
                            <validation-messages [formCtrl]="emailTemplateForm.controls.type"></validation-messages>
                        </div>

                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-light-primary fw-bold"
                        (click)="closeModal()"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [buttonBusy]="saving"
                        [disabled]="!emailTemplateForm.valid"
                        [busyText]="l('SavingWithThreeDot')"
                        (click)="saveEmailTemplate()"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ isEditMode ? 'Update' : 'Save' }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>