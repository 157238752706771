<div id="widget_container" *ngIf="userConfiguredCloudinary">

</div>


<div class="" *ngIf="!userConfiguredCloudinary && !loading else showloader">
  <div class="gallery_1">
    <div class="responsive-container-block bigContainer">
      <div class="responsive-container-block Container mt-0">
        <!-- <div class="d-flex w-100 justify-content-between align-items-center mb-12 p-1 ml-header">
                <h1>Media library</h1>
                 <div class="d-flex gap-3 align-items-center">
                        <input type="text" class="search_media_library p-2" placeholder="Search Media Library">
                        <button [routerLink]="['/app/admin/integrations']"  class="btn btn-primary">Upload</button>
                        <i class="fas fa-ellipsis-h"></i>
                 </div>
            </div> -->

        <div class="d-flex justify-content-between gap-3 border-bottom pb-3">
          <div class="d-flex gap-3 align-items-center">
            <h4>Media Library</h4>
            <ul class="d-flex p-0 ls-0 mb-0" style="visibility: hidden;">
              <li class="p-3">Home</li>
              <li class="p-3">Assets</li>
              <li class="p-3">Folder</li>
              <li class="p-3">Collection</li>
              <li class="p-3">Moderation</li>
            </ul>
          </div>
          <div class="d-flex align-items-center gap-2">
            <ng-container *ngIf="showInsertBtn">
              <button class="btn btn-primary" (click)="onInsert()">
                Insert
              </button>
            </ng-container>

            <button (click)="CloseDialog()" [routerLink]="['/app/admin/integrations']" class="btn btn-primary">
              Upload
            </button>
          </div>
        </div>
        <!-- <div class="border-bottom p-2" style="visibility: hidden;">
          <h4 >Advance Search</h4><br>
          <ul class="d-flex gap-3 p-0 m-0 ls-0" >
            <li>
              <input class="form-control" type="text" name="" id="">
            </li>
            <li><a href="" class="tags-filter">Display Name</a></li>
            <li><a href="" class="tags-filter">Folder</a></li>
            <li><a href="" class="tags-filter">Creation Date</a></li>
            <li><a href="" class="tags-filter">tag</a></li>
            <li><a href="" class="tags-filter">Format</a></li>
            <li><a href="" class="tags-filter">Asset Type</a></li>
            <li><a href="" class="tags-filter">Orientation</a></li>
            <li><button class="btn">Add more</button></li>
          </ul>
        </div> -->


        <div class="responsive-container-block imgContainer mt-3" *ngIf="assets">
          <div class="project image-box position-relative" *ngFor="let item of assets; let x = index">
            <input #checkbox *ngIf="componentType != 0 && componentType" type="checkbox" name="" [checked]="isSelected(x)"  [(ngModel)]="item.isChecked" class="position-absolute select-image"  [ngClass]="{'highlight': item.isChecked}" [id]="'ImgId'+x"
              (change)="selectCloudinaryData(item,item.isChecked);">
            <ng-container *ngIf="item.resource_type === 'image' && item.format === 'jpg' ">
              <label class="w-100 h-100" [for]="'ImgId'+x">
                <img class="smallImage" [src]="item.secure_url" loading="lazy">
              

              </label>
            </ng-container>
            <ng-container *ngIf="item.resource_type === 'video'">
              <label class="w-100 h-100" [for]="'ImgId'+x" (click)="selectvideo(x)">
                <video controls class="bg-black video_styles">
                  <source [src]="item.secure_url" type="video/mp4">
                </video>

              </label>
            </ng-container>

            <ng-container *ngIf="item.resource_type === 'image' &&  item.format === 'pdf'">
              <label class="w-100 h-100" [for]="'ImgId'+x">
                <img class="smallImage pdf_class" [src]="item.secure_url.replace('.pdf','.jpg')" loading="lazy">
              </label>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #showloader>
  <p-progressSpinner class="progress-spinner-height progress-spinner-sm me-3" strokeWidth="4"></p-progressSpinner>
</ng-template>