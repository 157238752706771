<div [@routerTransition] class="client-container container">
    <div class="c-route-page-container ">
        <div class="p-28">
            <sub-header [title]="'Email Template' | localize">
            <div role="actions">
                <div class="btn-group" dropdown placement="button right">
                    <button id="dropdownButtonExcelOperations" dropdownToggle type="button"
                        class="btn btn-outline btn-outline-success btn-active-light-success dropdown-toggle me-1 btn-ext"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        aria-controls="dropdownMenuExcelOperations">
                        <i class="far fa-file-excel btn-md-icon btn-ext"></i>
                        <span class="d-none d-md-inline-block">
                            {{ 'ExcelOperations' | localize }}
                        </span>
                    </button>
                    <ul id="dropdownMenuExcelOperations" class="dropdown-menu dropdown-menu-right dropdown-excel-operations"
                        role="menu" *dropdownMenu aria-labelledby="dropdownButtonExcelOperations">
                        <li role="menuitem">
                            <a class="dropdown-item text-dark" id="ExportUsersToExcelButton" (click)="exportToExcel()"
                                href="javascript:;">
                                <i class="fa fa-download me-2 mt-1 text-dark" style="font-size: 1rem"></i>
                                {{ 'ExportToExcel' | localize }}
                            </a>
                        </li>
                    </ul>
                </div>
    
                <button *ngIf="'Pages.Client.Create' | permission" (click)="createEmailTemplate()" class="btn btn-primary btn-ext">
                    <i class="fa fa-plus btn-md-icon"></i>
                    <span class="d-none d-md-inline-block">
                        {{ 'Add new email template' | localize }}
                    </span>
                </button>
            </div>
        </sub-header>
        </div>
    </div>
    <div [class]="containerClass">
        <div class="card card-custom gutter-b">
            <div class="card-body">
                <div>
                    <div class="row align-items-center mb-4">
                        <div class="col-xl-12">
                            <div class="mb-5 m-form__group align-items-center">
                                
                                <div class="input-wrapper">
                                    <img src="../../../assets/media/icons/uil_search.svg" (click)="getEmailTemplates()" class="serach-input cursor-pointer" />
                                    <input (keyup)="getEmailTemplates()" [(ngModel)]="searchFilter" name="filterText" autoFocus type="text" class="input-wrapper-inner"
                                        placeholder="Search here" />
                                    <img *ngIf="searchFilter" src="../../../assets/icons/close.svg" (click)="clearFilter()"  class="clear-search ms-3 w-15px me-3 cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable sortMode="multiple" (onLazyLoad)="getEmailTemplates($event)"
                            [value]="primengTableHelper.records"
                            rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" scrollDirection="horizontal"
                            [tableStyle]="{'min-width': '50rem'}"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 130px">
                                        {{ 'Actions' | localize }}
                                    </th>
                                    <th style="min-width: 150px" pSortableColumn="subject">
                                        {{ 'Subject' | localize }}
                                        <p-sortIcon field="subject"></p-sortIcon>
                                    </th>
                                    <th style="min-width: 150px" pSortableColumn="body">
                                        {{ 'Body' | localize }}
                                        <p-sortIcon field="body"></p-sortIcon>
                                    </th>
                                    <th style="min-width: 150px" pSortableColumn="type">
                                        {{ 'Type' | localize }}
                                        <p-sortIcon field="type"></p-sortIcon>
                                    </th>
                                    
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="min-width: 130px">
                                        <div class="btn-group" dropdown placement="bottom left" container="body">
                                            <button id="dropdownButton" type="button"
                                                class="btn btn-primary btn-sm dropdown-toggle btn-ext" dropdownToggle
                                                aria-controls="dropdownMenu">
                                                <i class="fa fa-cog"></i>
                                                <span class="caret"></span>
                                                {{ 'Actions' | localize }}
                                            </button>
                                            <ul id="dropdownMenu" class="dropdown-menu" role="menu" *dropdownMenu
                                                aria-labelledby="dropdownButton">
                                                <li role="menuitem">
                                                    <a href="javascript:;" class="dropdown-item"
                                                        (click)="createOrEditEmailTemplateComponent.showModal(record?.emailTemplate);">
                                                        {{ 'Edit' | localize }}
                                                    </a>
                                                </li>
                                                <li role="menuitem">
                                                    <a href="javascript:;" class="dropdown-item"
                                                        (click)="deleteSelectedEmailTemplate(record?.emailTemplate)">
                                                        {{ 'Delete' | localize }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="min-width: 150px">
                                        <span class="p-column-title">{{ 'Subject' | localize }}</span>
                                        {{ record?.emailTemplate?.subject }}
                                    </td>
                                    <td style="min-width: 150px">
                                        <span class="p-column-title">{{ 'Body' | localize }}</span>
                                        {{ record?.emailTemplate?.body }}
                                    </td>
                                    <td style="min-width: 150px">
                                        <span class="p-column-title">{{ 'Type' | localize }}</span>
                                        {{ record?.emailTemplate?.type }}
                                    </td>
                                    
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{ 'NoData' | localize }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="getEmailTemplates($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                [showCurrentPageReport]="true" [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                "></p-paginator>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>

<app-create-or-edit-email-template #createOrEditEmailTemplateComponent (onModalSave)="getEmailTemplates()"></app-create-or-edit-email-template>