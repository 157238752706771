import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";


@Injectable()
export class CommonBroadcastService<T>
{
  protected events: KeyValuePair<Subject<T>>[];

  constructor() {
    this.events = [];
  }

  public BroadcastEvent(key: string, value: any) {
    let keyValuePair = this.events.find(event => event.key === key);

    if (!keyValuePair) {
      keyValuePair = new KeyValuePair<Subject<T>>(key, new Subject<T>());
      this.events.push(keyValuePair);
    }

    keyValuePair.value.next(value);
  }

  public GetEvent(key: string): Observable<T> {
    let keyValuePair = this.events.find(sub => sub.key === key);

    if (!keyValuePair) {
      const subject = new Subject<T>();
      keyValuePair = new KeyValuePair<Subject<T>>(key, subject);
      this.events.push(keyValuePair);
    }

    return keyValuePair.value.asObservable();
  }

}



// Used to store key value pairs
export class KeyValuePair<T>
{
  public key: string;
  public value: T;

  constructor(key: string, value: T) {
    this.key = key;
    this.value = value;
  }
}

