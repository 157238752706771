import { Injectable, Inject} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// rxjs
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxService {
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  private http: HttpClient;
  private baseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
    this.baseUrl = environment.remoteServiceBaseUrl;
  }

  
  createTax(payload) {
    let url_ = this.baseUrl + '/api/services/app/TaxService/CreateOrganizationTax';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.post(url_, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateTax(payload) {
    let url_ = this.baseUrl + '/api/services/app/TaxService/UpdateOrganizationTax';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.put(url_, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteTax(id: number) {
    let url_ = this.baseUrl + '/api/services/app/TaxService/DeleteTax';
    url_ = url_.replace(/[?&]$/, '');

    let params = new HttpParams();
    params = params.append('Id', id);

    return this.http.delete(url_, { params }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getTaxAll(pageOptions) {
    let url_ = this.baseUrl + '/api/services/app/TaxService/GetTaxAll';
    url_ = url_.replace(/[?&]$/, '');

    let params = new HttpParams();
    params = params.append('page', pageOptions?.currentPage);
    params = params.append('ItemPerPage', pageOptions?.itemsPerPage);

    return this.http
      .get(url_, { params })
      .pipe(
        map((response: any) => response)
      );
  }

  getTaxs() {
    let url_ = this.baseUrl + '/api/services/app/TaxService/GetTaxs';
    url_ = url_.replace(/[?&]$/, '');

    return this.http
      .get(url_)
      .pipe(
        map((response: any) => response)
      );
  }

}
