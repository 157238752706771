<!--  Cover Page Starts  -->
<div class="container-fluid main-page shadow" [ngStyle]="{'transform': isScale ? 'scale(0.305)' : 'none','transform-origin': isScale ? 'top left' : 'none'}">
    <div class="row cover-wrapper mx-0" [class.in-template]="isScale">
        <div class="cover-header-wrapper">
            <div class="template-header">
                <ng-container [ngSwitch]="isTemplateType">
                    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                        <h1 class="main-title border-b-white text-truncate cursor-pointer d-flex ">{{ quoteName }}</h1>
                        <img *ngIf="companyInfo?.imagePath" class="img-fluid" src="{{baseUrl}}/{{companyInfo?.imagePath}}" alt="Logo">
                    </ng-container>
                    <ng-container *ngSwitchCase="templateTypes.CreateTemplate">
                        <div class="editable-text f-grow" *ngIf="isTemplateType === templateTypes.CreateTemplate">
                            <h1 class="main-title border-b-white text-truncate mb-0" *ngIf="!editQuoteName" (mouseover)="showQuoteEditIcon = true" (mouseout)="showQuoteEditIcon = false" (click)="editQuoteName = true">
                                {{ quoteName }}<i class="icon-sz fa-solid fa-pencil iconz ms-4 fs-12 text-black pencil mt-4 position-absolute"></i>
                            </h1>
                            <input style="height: 40px;" *ngIf="editQuoteName" type="text" class="form-control custom-input" autoFocus #quoteNameField placeholder="Give this quote a title, e.g. Roof Quote" [(ngModel)]="quoteName" (keyup.enter)="onSetValue('quoteName')" (blur)="onSetValue('quoteName')" />
                        </div>
                        <img *ngIf="companyInfo?.imagePath" class="img-fluid" src="{{baseUrl}}/{{companyInfo?.imagePath}}" alt="Logo">
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="cover-contents-wrapper">
            <div class="cover-image" style="background-image:url('{{selectedImg?.uniqueFileName}}') ; font-size: inherit;">
                <!-- <div class="client-info-container"> -->
                <ng-container [ngSwitch]="isTemplateType">
                    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                        <!-- {{ templateInfo | json}} -->
                        <div class="client-info-container" *ngIf="client && templateInfo">
                            <div class="cover-image-info" [ngStyle]="{
                                    background: selectedImg?.uniqueFileName ? templateInfo?.templateColor : ''
                                }">
                                <div class="d-flex flex-column">
                                    <div class="text-truncate custom-title">{{ client.name }}</div>
                                    <!-- <div class="text-truncate custom-text text-capitalise">{{ client.address1 }} {{ client.address2 }}</div> -->
                                    <div class="text-truncate custom-text">{{ client.phone }}</div>
                                    <div *ngIf="quoteType !== 4" class="text-truncate custom-text">{{ client.email }}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="templateTypes.CreateTemplate">
                        <div class="client-info-container" *ngIf="client || templateInfo">
                            <div (click)="editClient = true" *ngIf="client && !editClient; else selectClient" [ngStyle]="{
                                    background: selectedImg?.uniqueFileName ? templateInfo?.templateColor : ''
                                }" class="cover-image-info cursor-pointer">
                                <div class="d-flex flex-column">
                                    <div class="text-truncate custom-title">{{ client.name }}</div>
                                    <!-- <div class="text-truncate custom-text text-capitalise">{{ client.address1 }} {{ client.address2 }}</div> -->
                                    <div *ngIf="quoteType !== 4" class="text-truncate custom-text">{{ client?.phone }}</div>
                                    <div *ngIf="quoteType !== 4" class="text-truncate custom-text">{{ client?.email }}</div>
                                </div>
                                <i (click)="editClient = true" class="icon-sz fa-solid fa-pencil ms-4 fs-12 text-white iconz" title="Edit Client"></i>
                            </div>
                        </div>
                        <ng-template #selectClient>
                            <div *ngIf="editClient || !client" class="client-dropdown" [ngStyle]="{background: selectedImg?.uniqueFileName ? templateInfo?.templateColor : ''}">
                                <div class="d-flex align-items-center">
                                    <div class="d-flex f-grow  flex-column" [style]="textMaxWidth()">
                                        <div class="custom-input-dropdown">
                                            <p-dropdown #clientDropdown pTooltip="we reserve the right to revoke this quotation if it is notice."  [tooltipOptions]="tooltipOptions" [disabled]="quoteType === quoteTranscationTypes.Template" (onChange)="onClientSelectionChange($event)" styleClass="client-list-overlay" [options]="clients" [(ngModel)]="client" optionLabel="name" [showClear]="true" placeholder="Choose a contact">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="flex align-items-center gap-2" *ngIf="client">
                                                        <div class="custom-title">{{ client.name }}</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header">
                                                    <div (click)="clientCreationModal.showModal(); clientDropdown.hide()" class="align-items-center d-flex gap-2 px-3 py-2">
                                                        <div [style.width]="'16px'" [style.height]="'16px'" class="align-items-center d-flex justify-content-center p-3">
                                                             <i class="fa fa-plus text-primary iconz"></i>
                                                        </div>
                                                        <div class="new-contact-link custom-title text-primary ">Add new customer</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-client let-index="rowIndex" pTemplate="item">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div [style.width]="'16px'" [style.height]="'16px'" [style.background-color]="client.avatarBg" class="align-items-center d-flex item-avatar justify-content-center p-3 rounded-circle text-white">{{getInitials(client.name)}}</div>
                                                        <div class="custom-title text-black">{{ client.name }}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-container *ngIf="selectedImg?.uniqueFileName; else dndSection">
                            <button class=" btn-remove-upload" (click)="removedCoverPhoto()">
                                <i class="fa-regular iconz fa-trash-can"></i>
                            </button>
                        </ng-container>
                        <ng-template #dndSection>
                            <app-upload-images *ngIf="isTemplateType == templateTypes.CreateTemplate;" (signal)="onCloudinaryCoverSignal($event)"></app-upload-images>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchDefault></ng-container>
                </ng-container>
                <!-- </div> -->
            </div>
        </div>
        <div class="container-fluid cover-footer-wrapper">
            <div class="footer row">
                <div class="col-12">
                    <div class="company-name">
                        <ng-container [ngSwitch]="isTemplateType">
                            <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
                                <span class="company-title text-truncate text-nowrap">{{ 'Roofing Company' }}</span>
                                <span class="company-date">11/25/2023</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                                <span class="company-title text-truncate text-nowrap">{{ templateInfo?.name }}</span>
                                <span class="company-web">{{ templateInfo?.web }}</span>
                                <span class="company-web mt-2">{{ previewDate | date: 'dd/MM/yyyy' }}</span>

                            </ng-container>
                            <ng-container *ngSwitchCase="templateTypes.CreateTemplate">
                                <span class="company-title text-truncate text-nowrap">{{ templateInfo?.name }}</span>
                                <span class="company-web">{{ templateInfo?.web }}</span>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                <div class="col-sm-4 footer-address-col d-flex justify-content-center">
                    <div class="company-info">
                        <span class="footer-section-details d-flex flex-row align-items-center justify-content-center">
                            <app-svg-icon  icon="QC-i-pin" size="24" />
                            <span class="d-flex flex-column" [style]="textMaxWidth()">
                                <ng-container [ngSwitch]="isTemplateType">
                                    <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
                                        <span class="text-address text-truncate text-nowrap w-100">Address (1) Line Here</span>
                                        <span class="text-address text-truncate text-nowrap w-100">City Here, Country</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                                        <span class="text-address text-truncate text-nowrap w-100">{{ templateInfo?.address }}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="templateTypes.CreateTemplate">
                                        <span class="text-address text-truncate text-nowrap w-100" pTooltip="Update your company's details in the 'Company Info' section."  tooltipPosition="top">{{ templateInfo?.address }}</span>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </span>
                    </div>
                </div>
                <div class=" col-sm-4 footer-address-col d-flex justify-content-center">
                    <div class="company-info" *ngIf="quoteType !== 4">
                        <span class="footer-section-details d-flex flex-row align-items-center justify-content-center" [style]="textMaxWidth()">
                            <app-svg-icon  icon="QC-i-call" size="24" />
                            <ng-container [ngSwitch]="isTemplateType">
                                <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
                                    <span class="text-address text-truncate text-nowrap w-100">+008 866 522346</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                                    <span class="text-address text-truncate text-nowrap w-100">{{ templateInfo && templateInfo?.phone }}</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="templateTypes.CreateTemplate">
                                    <span class="text-address text-truncate text-nowrap w-100" pTooltip="Update your company's details in the 'Company Info' section."  tooltipPosition="top">{{ templateInfo && templateInfo?.phone }}</span>
                                </ng-container>
                            </ng-container>
                        </span>
                    </div>
                </div>
                <div class=" col-sm-4 footer-address-col d-flex justify-content-center">
                    <div class="company-info" *ngIf="quoteType !== 4">
                        <span class="footer-section-details d-flex flex-row align-items-center justify-content-center" [style]="textMaxWidth()">
                            <app-svg-icon  icon="QC-i-mail" size="24" />
                            <ng-container [ngSwitch]="isTemplateType">
                                <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
                                    <span class="text-address text-truncate text-nowrap w-100">{{"info@company.com"}}</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                                    <span class="text-address text-truncate text-nowrap w-100">{{ templateInfo && templateInfo?.email }}</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="templateTypes.CreateTemplate">
                                    <span class="text-address text-truncate text-nowrap w-100" pTooltip="Update your company's details in the 'Company Info' section."  tooltipPosition="top">{{ templateInfo && templateInfo?.email }}</span>
                                </ng-container>
                            </ng-container>
                        </span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
<app-client-creation app-client-creation #clientCreationModal (onModalSave)="onCreateNewClient($event)"></app-client-creation>
