import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstLetter',
})
export class FirstLetterPipe implements PipeTransform {
    transform(title: string, key: boolean): string {
        if (key) {
            return title.charAt(0);
        }
        return title;
    }
}
