import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'signature-pad',
    standalone: true,
    templateUrl: './signature-pad.component.html',
    styleUrls: ['./signature-pad.component.scss'],
    imports: [CommonModule],
})
export class SignaturePadComponent implements AfterViewInit {
    @ViewChild('signPad') signPad: ElementRef<HTMLCanvasElement>;
    @Output() signatureSaved = new EventEmitter();
    @Input() width = 200;
    @Input() height = 100;
    @Input() image = null;
    @Input() isHideSaveButton: boolean = true;
    @Input() isHideClearButton: boolean = false;
    @Input() showImage: boolean = false;
    baseUrl = environment.remoteServiceBaseUrl;
    private signatureImg?: string;
    private sigPadElement: any;
    private context: any;
    isDrawing!: boolean;

    ngAfterViewInit(): void {
        if (this.signPad) {
            this.initPad();
        }
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(e: any): void {
        this.isDrawing = false;
    }

    onMouseDown(e: any): void {
        this.isDrawing = true;
        const coords = this.relativeCoords(e);
        this.context.moveTo(coords.x, coords.y);
    }

    onMouseMove(e: any): void {
        if (this.isDrawing) {
            const coords = this.relativeCoords(e);
            this.context.lineTo(coords.x, coords.y);
            this.context.stroke();
        }
    }

    clearSignature(): void {
        this.signatureImg = undefined;
        this.image = null;

        if (this.signPad) {
            this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
            this.context.beginPath();
        } else {
            setTimeout(() => {
                this.initPad();
            }, 0);
        }
    }

    saveSignature(): void {
        setTimeout(() => {           
        this.signatureImg = this.sigPadElement.toDataURL('image/png');
        this.signatureSaved.emit(this.signatureImg);
        }, 0);
    }

    private initPad(): void {
        this.sigPadElement = this.signPad.nativeElement;
        this.context = this.sigPadElement.getContext('2d');
        this.context.strokeStyle = '#000';
    }

    private relativeCoords(event: any): { x: number; y: number } {
        const bounds = event.target.getBoundingClientRect();
        const cords = {
            clientX: event.clientX || event.changedTouches[0].clientX,
            clientY: event.clientY || event.changedTouches[0].clientY,
        };
        const x = cords.clientX - bounds.left;
        const y = cords.clientY - bounds.top;
        return { x, y };
    }
}
