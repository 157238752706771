import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditEmailTemplateComponent } from './create-or-edit-email-template/create-or-edit-email-template.component';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { HttpClient } from '@microsoft/signalr';
import { AppConsts } from '@shared/AppConsts';
import { EmailTemplatesServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { LazyLoadEvent } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { finalize } from 'rxjs';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css'],
  animations: [appModuleAnimation()],

})
export class EmailTemplateComponent  extends AppComponentBase implements OnInit {
  @ViewChild('createOrEditEmailTemplateComponent', { static: true }) createOrEditEmailTemplateComponent: CreateOrEditEmailTemplateComponent;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('ExcelFileUpload', { static: false }) excelFileUpload: FileUpload;

  // responseData: any;
  actions: any;

  // filters
  searchFilter = '';

  uploadUrl: string;
  howToCreateClientUrl = 'https://companyquote.org/docs/how-to-add-a-client/';
  subjectFilter: string;
  bodyFilter: string;
  typeFilter: string;

  constructor(
    injector: Injector,
    public emailTemplateService: EmailTemplatesServiceProxy,
    private _fileDownloadService: FileDownloadService,
  ) {
    super(injector);
    this.uploadUrl = AppConsts.remoteServiceBaseUrl + '/Clients/ImportFromExcel';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.primengTableHelper.adjustScroll(this.dataTable);
  }

  getEmailTemplates(event?: LazyLoadEvent) {debugger
    if (this.primengTableHelper.shouldResetPaging(event)) {
      this.paginator.changePage(0);
      if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
        return;
      }
    }

    this.primengTableHelper.showLoadingIndicator();

    this.emailTemplateService.getAll(
      this.searchFilter || '',
      this.subjectFilter || '',
      this.bodyFilter || '',
      this.typeFilter || '',
      this.primengTableHelper.getSorting(this.dataTable),
      this.primengTableHelper.getSkipCount(this.paginator, event),
      this.primengTableHelper.getMaxResultCount(this.paginator, event),
    )
    .pipe(finalize(() => this.primengTableHelper.hideLoadingIndicator()))
    .subscribe(
      (data) => {
        this.primengTableHelper.totalRecordsCount = data.totalCount;
        this.primengTableHelper.records = data.items;
        this.primengTableHelper.hideLoadingIndicator();
      }, error => {
        this.primengTableHelper.hideLoadingIndicator();
      }
    );
  }

  createEmailTemplate() {
    this.createOrEditEmailTemplateComponent.showModal();
  }

  deleteSelectedEmailTemplate(client) {
    this.message.confirm(`Are you sure you want to delete client ${client?.name}`, '', (isConfirmed) => {
      if (isConfirmed) {
        this.emailTemplateService.delete(client.id).subscribe(
          response => {
            this.notify.success(this.l('SuccessfullyDeleted'));
            this.paginator.changePage(this.paginator.getPage());
          }, error => {
            this.notify.error(`Error: ${error.message}`);
          }
        );
      }
    });
  }

  onFilter() {
    if (this.searchFilter === '') {
      this.getEmailTemplates();
    }
  }

  navigateToHowItWorks() {
    window.open(this.howToCreateClientUrl, '_blank');
  }

  exportToExcel() {
    const filters = {
      searchValue: this.searchFilter || '',
      sorting: this.primengTableHelper.getSorting(this.dataTable)
    }

    this.emailTemplateService.getEmailTemplatesToExcel(
      this.searchFilter || '',
      this.subjectFilter || '',
      this.bodyFilter || '',
      this.typeFilter || ''
    ).subscribe(
      result => {
        this._fileDownloadService.downloadTempFile(result);
      }
    );
  }
  
  clearFilter(): void{
    this.searchFilter = '';
    this.getEmailTemplates();
  }

}
