<!--  Project List Template Starts  -->
<ng-container [ngSwitch]="isTemplateType">

    <!--  Static Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
        <div class="container-fluid main-page shadow">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-column">
                            <h1 class="template-title">Project cost</h1>
                            <!-- <span class="template-title-border"></span> -->
                        </div>
                    </div>

                    <div class="body-layout">

                        <!--  Custom Table Wrapper Starts  -->
                        <div class="custom-table-wrapper">
                            <div class="table-responsive">
                                <table class="table custom-table">
                                    <thead class="custom-table-header">
                                        <tr class="custom-table-header-row">
                                            <th class="custom-table-header-col">Description</th>
                                            <th class="custom-table-header-col">Qty</th>
                                            <th class="custom-table-header-col">Tax</th>
                                            <th class="custom-table-header-col">Price</th>
                                            <th class="custom-table-header-col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody class="custom-table-body">
                                        <tr class="custom-table-body-row">
                                            <td class="custom-table-body-col">
                                                <div class="mb-0 custom-media-card">
                                                    <div class="row g-3">
                                                        <div class="col-3 col-md-auto">
                                                            <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                        </div>
                                                        <div class="col-9 col-md-10">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Sample</h5>
                                                                <p class="card-text">It is a long established fact that a reader will be distracted by the readable</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">1</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">Tax</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                        </tr>
                                        <tr class="custom-table-body-row">
                                            <td class="custom-table-body-col">
                                                <div class="mb-0 custom-media-card">
                                                    <div class="row g-3">
                                                        <div class="col-3 col-md-auto">
                                                            <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                        </div>
                                                        <div class="col-9 col-md-10">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Sample</h5>
                                                                <p class="card-text">It is a long established fact that a reader will be distracted by the readable</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">1</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">Tax</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                        </tr>
                                        <tr class="custom-table-body-row">
                                            <td class="custom-table-body-col">
                                                <div class="mb-0 custom-media-card">
                                                    <div class="row g-3">
                                                        <div class="col-3 col-md-auto">
                                                            <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                        </div>
                                                        <div class="col-9 col-md-10">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Sample</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">1</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">Tax</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                        </tr>
                                        <tr class="custom-table-body-row">
                                            <td class="custom-table-body-col">
                                                <div class="mb-0 custom-media-card">
                                                    <div class="row g-3">
                                                        <div class="col-3 col-md-auto">
                                                            <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                        </div>
                                                        <div class="col-9 col-md-10">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Sample</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">1</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">Tax</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  Custom Table Wrapper Ends  -->

                        <!--  Custom Table Wrapper Starts  -->
                        <div class="custom-table-wrapper">
                            <div class="table-responsive">
                                <table class="table custom-table">
                                    <thead class="custom-table-header">
                                        <tr class="custom-table-header-row">
                                            <th class="custom-table-header-col" colspan="5">Sample Title</th>
                                        </tr>
                                    </thead>
                                    <tbody class="custom-table-body">
                                        <tr class="custom-table-body-row">
                                            <td class="custom-table-body-col">
                                                <div class="mb-0 custom-media-card">
                                                    <div class="row g-3">
                                                        <div class="col-3 col-md-auto">
                                                            <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                        </div>
                                                        <div class="col-9 col-md-10">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Sample</h5>
                                                                <p class="card-text">It is a long established fact that a reader will be distracted by the readable</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">1</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">Tax</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                        </tr>
                                        <tr class="custom-table-body-row">
                                            <td class="custom-table-body-col">
                                                <div class="mb-0 custom-media-card">
                                                    <div class="row g-3">
                                                        <div class="col-3 col-md-auto">
                                                            <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                        </div>
                                                        <div class="col-9 col-md-10">
                                                            <div class="card-body">
                                                                <h5 class="card-title">Sample</h5>
                                                                <p class="card-text">It is a long established fact that a reader will be distracted by the readable</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">1</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">Tax</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                            <td class="custom-table-body-col">
                                                <p class="custom-table-text">$00.00</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  Custom Table Wrapper Ends  -->

                        <!--  Custom List Group Starts  -->
                        <ul class="list-group list-group-flush custom-list-group">
                            <li class="list-group-item">
                                <span class="custom-text">Subtotal</span>
                                <strong class="custom-text">$00.00</strong>
                            </li>
                            <li class="list-group-item">
                                <span class="custom-text">Tax</span>
                                <strong class="custom-text">$00.00</strong>
                            </li>
                            <li class="list-group-item">
                                <span class="custom-text">Total (CAD)</span>
                                <strong class="custom-text">$00.00</strong>
                            </li>
                        </ul>
                        <!--  Custom List Group Starts  -->

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--  Static Template Ends  -->

    <!--  Preview Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
        <div class="container-fluid main-page" *ngIf="data">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-column">
                            <h1 class="template-title">{{ data ? data.name : '-' }}</h1>
                            <!-- <span class="template-title-border"></span> -->
                        </div>
                        <div class="body-layout">
                            <!--  Custom Table Wrapper Starts  -->
                            <div class="custom-table-wrapper">
                                <div class="table-responsive">
                                    <table class="table custom-table">
                                        <thead class="custom-table-header">
                                            <tr class="custom-table-header-row">
                                                <th class="custom-table-header-col" [ngStyle]="{'background': templateInfo?.templateColor ? templateInfo?.templateColor : ''}">Description</th>
                                                <th class="custom-table-header-col" [ngStyle]="{'background': templateInfo?.templateColor ? templateInfo?.templateColor : ''}" [class.d-none]="!(isQuantityEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.quantity">Qty</th>
                                                <th class="custom-table-header-col" [ngStyle]="{'background': templateInfo?.templateColor ? templateInfo?.templateColor : ''}" [class.d-none]="!(isTaxEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.tax">Tax</th>
                                                <th class="custom-table-header-col" [ngStyle]="{'background': templateInfo?.templateColor ? templateInfo?.templateColor : ''}" [class.d-none]="!(isPriceEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.price">Price</th>
                                                <th class="custom-table-header-col" [ngStyle]="{'background': templateInfo?.templateColor ? templateInfo?.templateColor : ''}" [class.d-none]="!(isPriceEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody class="custom-table-body" *ngFor="let price of  data.jsonValue.PriceList">
                                            <tr class="custom-table-body-row" *ngIf="price.Section">
                                                <td class="custom-table-body-col">{{ price.Section }}</td>
                                            </tr>
                                            <tr class="custom-table-body-row" *ngFor="let product of price.Prices.slice(0, 5)">
                                                <td class="custom-table-body-col">
                                                    <div class="mb-0 custom-media-card">
                                                        <div class="row g-3">
                                                            <!-- <div class="col-3 col-md-auto">
                                                                <img src="../../../../../assets/media/templates/project-cost-img.png" class="img-fluid rounded-start custom-media-img" alt="project-cost-img">
                                                            </div> -->
                                                            <div class="col-9 col-md-10">
                                                                <div class="card-body">
                                                                    <h5 class="card-title">{{ product.ItemName }}</h5>
                                                                    <p class="card-text">{{ product.Description}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="custom-table-body-col" [class.d-none]="!(isQuantityEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.quantity">
                                                    <p class="custom-table-text">{{ product.Quantity || '-' }}</p>
                                                </td>
                                                <td class="custom-table-body-col" [class.d-none]="!(isTaxEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.tax">
                                                    <p class="custom-table-text">{{ ((taxes | TaxId : product.Tax) && (taxes | TaxId : product.Tax).name) || '-' }}</p>
                                                </td>
                                                <td class="custom-table-body-col" [class.d-none]="!(isPriceEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.price">
                                                    <p class="custom-table-text">{{ product.Price || '-' }}</p>
                                                </td>
                                                <td class="custom-table-body-col" [class.d-none]="!(isPriceEnabled$ | async) || !(projectCostVisibilityControl$ | async)?.total">
                                                    <p class="custom-table-text">{{ product.total || '-' }}</p>
                                                </td>
                                            </tr>
                                            <tr class="custom-table-body-row" *ngIf="!data.jsonValue.PriceList">
                                                <td class="custom-table-body-col no-data">No Item Found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--  Custom Table Wrapper Ends  -->

                            <!--  Custom List Group Starts  -->
                            <ul class="list-group list-group-flush custom-list-group">
                                <li class="list-group-item">
                                    <span class="custom-text">Subtotal</span>
                                    <strong class="custom-text">${{ data.jsonValue.SubTotalPrice || '00.00' }}</strong>
                                </li>
                                <li class="list-group-item">
                                    <span class="custom-text">Total Tax</span>
                                    <strong class="custom-text">${{ getItemTotalTax() | number : '2.2-2' || '00.00' }}</strong>
                                </li>
                                <li class="list-group-item">
                                    <span class="custom-text">Total (CAD)</span>
                                    <strong class="custom-text">${{ data.jsonValue.TotalPrice || '00.00'}}</strong>
                                </li>
                            </ul>
                            <!--  Custom List Group Starts  -->

                        </div>
                        <!-- <div class="template-footer">
                            <span class="template-footer-page-no">{{ data ? data.index : '3' }}</span>
                            <span class="template-footer-company">Roof Company</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--  Preview Template Ends  -->

</ng-container>
<!--  Project List Template Ends  -->