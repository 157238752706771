import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// rxjs
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppConsts } from '@shared/AppConsts';

@Injectable({
    providedIn: 'root'
})
export class QuoteImageService {
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = environment.remoteServiceBaseUrl;
    }


    getAllImages(quoteId) {
        let url_ = this.baseUrl + '/api/services/app/FileService/GetAllByQuoteId';
        url_ = url_.replace(/[?&]$/, '');

        let params = new HttpParams();
        params = params.append('QuoteId', quoteId);

        return this.http.get(url_, { params }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    deleteQuoteImage(payload) {
        let url_ = this.baseUrl + '/FileUpload4Quote/RemoveQuoteFile';
        url_ = url_.replace(/[?&]$/, '');

        return this.http.post(url_, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    uploadQuoteImage(payload: any, quoteId = ''): Observable<any> {
        let url_ = this.baseUrl + '/FileUpload4Quote/UploadCoverFile';
        if (quoteId) {
            url_ = url_ + `?quoteId=${quoteId}`;
        }

        url_ = url_.replace(/[?&]$/, '');

        let options_ : any = {
            body: payload,
        };

        return this.http.request("post", url_, options_);
    }

    uploadQuotePdf(payload: any): Observable<any> {
        let url_ = this.baseUrl + '/FileUpload4Quote/UploadPDFAttachFile';
        url_ = url_.replace(/[?&]$/, '');

        let options_ : any = {
            body: payload,
        };

        return this.http.request("post", url_, options_);
    }

    uploadQuoteDetailImage(quoteDetailId: string, payload: any) {
        let url_ = `${this.baseUrl}/FileUpload4Quote/UploadQuoteDetailFile?QuoteDetailId=${quoteDetailId}`;
        url_ = url_.replace(/[?&]$/, '');

        return this.http.post(url_, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    UploadQuoteImageSignatureFile(quoteId: string, payload: any) {
        let url_ = `${this.baseUrl}/FileUpload4Quote/UploadQuoteImageSignatureFile`;

        if (quoteId) {
            url_ =  `${url_}?quoteId=${quoteId}`;
        }
        
        url_ = url_.replace(/[?&]$/, '');

        return this.http.post(url_, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    downloadPdf(endPoint: string, fileName: string): Observable<Blob> {
        let url_ = `${this.baseUrl}/FileUpload4Quote/${endPoint}?filename=${fileName}`;
        url_ = url_.replace(/[?&]$/, '');

        return this.http.get(url_, { responseType: 'blob'});
    }

    uploadQuoteImageFileForSignClient(payload: any) {
        let url_ = `${this.baseUrl}/FileUpload4Quote/GetAuthToken`;
        url_ = url_.replace(/[?&]$/, '');

        return this.http.post(url_, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    uploadImageToCloudinary(payload:any){
        let url_ = `https://api.cloudinary.com/v1_1/${AppConsts.cloudName}/image/upload`;
        url_ = url_.replace(/[?&]$/, '');

        return this.http.post(url_, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getCloudinaryAuthToken(){
        let url_ = `${this.baseUrl}/FileUpload4Quote/GetAuthToken`;
        url_ = url_.replace(/[?&]$/, '');

        return this.http.get(url_).pipe(
            map((response: any) => {
                return response;
            })
        );

    }
}
