import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { CloudinaryConnection, CloudinaryServiceProxy, IResource } from '@shared/service-proxies/service-proxies';
import { MessageService } from 'abp-ng2-module';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'app-cloudinary-media-library',
  templateUrl: './cloudinary-media-library.component.html',
  styleUrls: ['./cloudinary-media-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudinaryMediaLibraryComponent {

  cloudinaryToken = '';
  insertedData = null;
  assets: IResource[] = [];
  loading: boolean = true;
  userConfiguredCloudinary: boolean = false;
  mosiacClass: string[] = ['project1', 'project2', 'project3', 'project4', 'project5', 'project6']
  componentType: number = 0;
  ImageGalleryData = [];
  showInsertBtn = false;

  cloudName = AppConsts.cloudName;
  apiKey = AppConsts.apiKey

  constructor(
    private readonly _dialogRef: DynamicDialogRef,
    private _cloudinary: CloudinaryServiceProxy,
    private readonly cdr: ChangeDetectorRef,
    private config: DynamicDialogConfig,
    private readonly _message:MessageService
  ) {

  }


  ngOnInit() {

    this.componentType = this.config.data;
    this._cloudinary.getCloudinaryConnection().subscribe({
      next: (res: CloudinaryConnection) => {
        if (res.apiKey) {
          this.cloudName = res.cloudName;
          this.apiKey = res.apiKey;
          this.showCloudinaryMediaLibrary();
        }
        else {
          this.getAssetsOfDefaultAccount();
        }
      },
      error: (res: any) => {

      }
    })

  }


  showCloudinaryMediaLibrary() {
    this.userConfiguredCloudinary = true;
    this.loading = false;
    this.cdr.detectChanges();
    (window as any).ml = (window as any).cloudinary.openMediaLibrary({
      cloud_name: this.cloudName,
      api_key: this.apiKey,
      remove_header: false,
      multiple: true,
      insert_caption: 'Insert',
      inline_container: "#widget_container",
      default_transformations: [
        []
      ],
      button_class: 'myBtn',
      button_caption: 'Select Image or Video'
    }, {
      insertHandler: (data) => {
        data.assets.forEach(asset => {
          console.log("Inserted asset:",
            JSON.stringify(asset, null, 2))
        })
        this.onCloseDialog(data);
      }
    },
      document.getElementById("open-btn")
    );
  }


  onCloseDialog(data: any): void {
    if (this.componentType === 1 && data.assets.some(a=>a.resource_type === 'video')) {
      this._dialogRef.close();
      this._message.warn("Only images can be added on this page please select images only")
      return;
    }
    else if (this.componentType === 2 && data.assets.some(a=>a.resource_type === 'image')) {
      this._dialogRef.close();
      this._message.warn("Only videos can be added on this page please select video only")
      return;
    }
    else if (this.componentType === 3 && data.assets.some(a=>a.resource_type === 'video')) {
      this._dialogRef.close();
      this._message.warn("Only images can be added on this page please select images only")
      return;
    }

    else if (this.componentType === 4 && data.assets.some(a=>a.format !== 'pdf')) {
      this._dialogRef.close();
      this._message.warn("Only PDF can be added on this page please select PDF only")
      return;
    }
    this._dialogRef.close(data);
  }

  getAssetsOfDefaultAccount() {
    this._cloudinary.getAssets().subscribe(res => {
      this.assets = res;
      this.assets.map((asset)=>{
        asset['isChecked'] = false;
      })
      if (this.componentType === 2) {
        this.assets = this.assets.filter(a=>a.resource_type === 'video');
      }
      else if(this.componentType === 1 || this.componentType === 3){
        this.assets = this.assets.filter(a=>a.resource_type === 'image' && a.format === 'jpg');
      }
      else if(this.componentType === 4){
        this.assets = this.assets.filter(a=>a.format === 'pdf');
      }
      this.loading = false;
      this.cdr.detectChanges();
    })
  }

  getRandomClass() {
    const randomNumber = Math.floor(Math.random() * 6) + 1;
    return `project${randomNumber}`

  }

  selectCloudinaryData(item: IResource,checked:boolean) {

    if (this.componentType === 1) {
      this._dialogRef.close(item);

    }
    else if (this.componentType === 2) {
      this._dialogRef.close(item);

    }
    else if (this.componentType === 4) {
      this._dialogRef.close(item);

    }
    else if (this.componentType === 3 && checked) {
      this.showInsertBtn = true

    if(!this.ImageGalleryData.some(g=>g.asset_id === item.asset_id))
      this.ImageGalleryData.push(item);
    }
  }

  onInsert() {
    this._dialogRef.close(this.ImageGalleryData);
  }

  CloseDialog() {
    this._dialogRef.close();
  }
  isSelected(x: number): boolean {
    return this.selectedVideos.some(i => i == x)
  }
  selectedVideos: number[] = []
  selectvideo(x: number) {
    if (this.selectedVideos.some(i => i == x)) {
     let cIndex = this.selectedVideos.findIndex(v => v === x);
     this.selectedVideos.splice(cIndex,1)
    } else {

      this.selectedVideos.push(x)
    }
  }
}
