import { Component } from '@angular/core';

@Component({
    selector: 'app-section-templates-container',
    templateUrl: './section-templates-container.component.html',
    styleUrls: ['./section-templates-container.component.scss'],
})
export class SectionTemplatesContainerComponent {
    priceListJson = {};
}
