import { ITaxListDto } from '@shared/service-proxies/service-proxies';

export type QuotePageTypes = 'text' | 'cost' | 'image_text' | 'pdf';
export enum QuotePageKey {
    TEMPLATE = 0,
    APPROVAL = 1,
    PROJECT_COST = 2,
    ATTACH_PDF = 4,
    PROJECT_DESCRIPTION = 8,
    INSPECTION_REPORT = 16,
    INSPECTION_REPORT_VIDEO = 17,
    INSPECTION_REPORT_GALLERY = 18,
}

export enum TemplateViewTypesEnum {
    StaticTemplate,
    CreateTemplate,
    PreviewTemplate,
}

export interface QuoteItems {
    sections: ItemsSection[];
    discount: number;
    deposit?: number;
    advance?: number;
}

export interface ItemsSection {
    title: string;
    items: SectionItem[];
    editable?: boolean;
}
export interface ItemsSection2 {
    title: string;
    items: SectionItem2[];
}

export interface SectionItem {
    image?: string;
    id: number;
    item: string;
    itemId?: string;
    quantity: number;
    price: number;
    tax: ITaxListDto;
    description?: string;
    editable: boolean;
}
export interface SectionItem2 extends SectionItem {
    unit?: string;
    unitNum?: number;
}
export interface materialList {
    sections: ItemsSection2[];
    discount: number;
    deposit?: number;
    advance?: number;
}
export interface IQuotePage {
    id?: string;
    name: string;
    key: QuotePageKey;
    enabled: boolean;
    order: number;
    json?: any;
    showActionMenu: boolean;
    isEditing?: boolean;
    depId: string; //copy id in variable for selection/compare,
    jsonValue?: any;
    index?: number;
}

export interface IQuotePageType {
    title: string;
    description: string;
    type: QuotePageTypes;
    icon: string;
    key: QuotePageKey;
}

export interface InspectionReport {
    index: number;
    image: {
        imageid: string;
        fileUniqueName: string;
        url: string;

    };
    title: string;
    description: string;
}

export interface InspectionVideoReport {
    index: number;
    image: {
        imageid: string;
        fileUniqueName: string;
        url: string;
        thumbnail_url:string;

    };
    title: string;
    description: string;
}

export interface IAttachedPdf {
    pdfDoc: string;
    thumbnailFile: string;
    path:string;
    pages:number
    imageUrls:any
}

export interface IProjectCustomPrice {
    subTotal: number;
    tax: number;
    taxId: number;
}

export interface InvoiceStatus {
    name: string;
}

export interface TemplateSignal {
    key: string;
    value: any;
}

export interface CloudinaryCoverUploadSignal{
  imageId:string;
  url:string;
  fileUniqueName:string;
}

export enum ZoomKey {
    MINUS,
    PLUS,
}

export enum ZoomScaleEnum {
    Level_200 = '1250px',
    Level_125 = '1095px',
    Level_100 = '835px',
    Level_75 = '780px',
    Level_50 = '700px',
    FIT = '100%',
}

export interface ZoomLavel {
    key: string;
    value: ZoomScaleEnum;
}

export interface IAuthorizedSigner {
    date: string;
    email: string;
    lastName: string;
    name: string;
    phone: string;
}
export interface IBrandInformation {
    colorOptions: string;
    manufacturerOrBrandName: string;
}

export interface IFinancialWarrantyProvisions {
    checked: boolean;
    content: string;
}

export interface IProjectDetails {
    projectNumber: string;
    projectType: string;
    workCommencementDateFromFrom: string;
    workCommencementDateFromTo: string;
    workCompletionDateFromFrom: string;
    workCompletionDateFromTo: string;
}

export interface IApprovalStatement {
    checked: boolean;
    content: string;
}

export interface IApprovalTemplate {
    AuthorizedSigner: IAuthorizedSigner;
    BrandInformation: IBrandInformation;
    FinancialWarrantyProvisions: IFinancialWarrantyProvisions[];
    ProjectDetails: IProjectDetails;
    approvalStatement: IApprovalStatement;
}

export interface IImage {
    Id: string;
    fileUniqueName: string;
}

export interface IContent {
    Html: string;
    title: string;
}

export interface IImageTextList {}

export interface IInspectionReport {
    ImageTextList: IImageTextList[];
}

export interface IPrice {
    Index: number;
    ItemName: string;
    Title: string;
    Description: string;
    Quantity: number;
    Price: number;
    Tax: number;
    LinePrice: number;
}

export interface IProjectList {
    Section: string;
    Prices: IPrice[];
}

export interface IProjectCost {
    PriceList: IProjectList;
    Discount: number;
    DiscountValue: number;
    SubTotalPrice: number;
    TotalPrice: number;
    TaxId: number;
    CustomPrice: number;
    IsCustomPrice: boolean;
    IsHiddenQuantity: boolean;
    IsHiddenLinePrice: boolean;
    IsHiddenLineTax: boolean;
}

export interface IProjectCostVisibilityControl {
    quantity: boolean;
    price: boolean;
    tax: boolean;
    total: boolean;
}


export interface InspectionReportGallery {
    index?: number;
    image: ImageGallery[];
    title: string;
    description: string;
}


export interface ImageGallery{
    imageid: string;
    fileUniqueName: string;
    url: string;
}
