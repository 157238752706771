<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex p-0">
        <div class="flex-1 d-flex d-flex-column bg-secondary text-primary">
            <div class="d-flex-column flex-10">
                <div class="d-flex d-flex-row flex-1">
                    <div class="flex-1 b-logo" [ngStyle]="{
                        'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                        'var(--b-image-url)', 
                        'background-position': 'center' 
                    }"></div>
                </div>

                <div class="d-flex d-flex-row flex-1 v-center h-center m-l-254 m-r-254" 
                    [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}"
                    style="font-size: 12mm; font-family: qc-Montserrat-ExtraBold;">
                    <div style="line-height: 1.42; -webkit-box-orient: vertical; display: -webkit-box; max-width: 100%; height: 125px; overflow-wrap: break-word; -webkit-line-clamp: 2; text-overflow: ellipsis; overflow: hidden; box-sizing: border-box; word-break: break-word;">
                        {{ templateInfo?.description1 }}
                    </div>
                </div>

                <div class="d-flex d-flex-row flex-5 bg-secondary text-primary" style="position:relative">
                    <div class="first-rotated-line"></div>
                    <div class="second-rotated-line" 
                    [ngStyle]="{'border-color': templateInfo?.templateColor ? 'transparent ' + templateInfo?.templateColor + ' transparent transparent' : 'text-primary'}"></div>
                    <div class="flex-1 b-q-logo" [ngStyle]="{
                        'background-image': selectedCoverImage?.uniqueFileName ? 'url(' + selectedCoverImage?.imgSrc + ')' :
                        'var(--b-q-image-url)' 
                    }"></div>
                    <div class="last-rotated-line"></div>
                </div>

                <div class="d-flex d-flex-row flex-1 bg-secondary p-l-254 p-r-254 d-flex flex-row align-items-start"
                    style="font-size: 15mm; padding-bottom: 6mm; color:#000000;">
                    <div class="d-flex-column flex-1">
                        <div class="d-flex-row" style="font-size: 8mm; margin-bottom: 3mm; font-weight: bold;">
                            Prepared for:
                        </div>
                        <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                            Client Name
                        </div>
                        <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                            Address
                        </div>
                        <div style="font-size: 5mm;" class="d-flex flex-column align-items-start address-ellipsis">
                            City, State, Zip Code
                        </div>
                    </div>
                    <div class="d-flex-column d-flex flex-column align-items-start">
                        <div class="d-flex-row" style="font-size: 8mm; margin-bottom: 3mm; font-weight: bold;">
                            Presented by:
                        </div>
                        <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                            {{ templateInfo?.userName }}
                        </div>
                        <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                            {{ templateInfo?.usePhoneNumer }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>
</body>

</html>