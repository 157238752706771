<div class="theme60-main-container">
    <nav class="top-menu d-flex align-items-center">
        <div class="mob-menu-btn btn btn-icon btn-active-color-primary w-35px h-35px d-none" id="kt_app_sidebar_mobile_toggle">
            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
<!--            <span class="svg-icon svg-icon-1">-->
<!--                <svg-->
<!--                    width="24"-->
<!--                    height="24"-->
<!--                    viewBox="0 0 24 24"-->
<!--                    fill="none"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                >-->
<!--                    <path-->
<!--                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"-->
<!--                        fill="currentColor"-->
<!--                    ></path>-->
<!--                    <path-->
<!--                        opacity="0.3"-->
<!--                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"-->
<!--                        fill="currentColor"-->
<!--                    ></path>-->
<!--                </svg>-->
<!--            </span>-->
            <!--end::Svg Icon-->
        </div>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center nav-menu">

        <theme60-brand></theme60-brand>

        <ul class="d-flex theme-60-top-bar-menu w-100">
            <!-- <li class="menu-item" *ngFor="let item of topMenu"
                [routerLink]="item.route !== '' ? [item.route] : []" (click)="onToggleItem($event, item)">
                {{ item.title }}

                <div class="menu-item-children-container" *ngIf="item.selected && item?.children?.length > 0">
                    <ng-container
                        [ngTemplateOutlet]="topMenuChildren"
                        [ngTemplateOutletContext]="{item: item}">
                    </ng-container>
                </div>
            </li> -->
            <top-bar-menu
            class="w-100"
            [menuClass]="
                'menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-400 align-items-stretch flex-grow-1 my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6'
            "
        ></top-bar-menu>
        </ul>

        <!-- <div class="d-flex theme-60-top-end-menu">
            <header-notifications class="me-3"></header-notifications>
            <user-menu></user-menu>
        </div> -->
        </div>
    </div>
</nav>

<div class="secondary-menu ">
    <div class="container">
        <div class="d-flex justify-content-between align-items-center">
        <!-- <ul class="d-flex py-4 gap-3">
            <li [class.active]="containsQuoteRoute" class="btn  btn-primary btn-ext btn-primary-ext" routerLink="/app/main/quotes">Quotes</li>
            <li [class.active]="containsInvoiceRoute" class="btn  btn-primary btn-ext btn-primary-ext" routerLink="/app/main/invoices">Invoices</li>
        </ul> -->
        <ul class="d-flex py-3 gap-3">
            <li [class.active]="containsQuoteRoute" class="btn btn-ext btn-shadow btn-shadow-ext" routerLink="/app/main/quotes">Quotes</li>
            <li [class.active]="containsInvoiceRoute" class="btn btn-ext btn-shadow btn-shadow-ext" routerLink="/app/main/invoices">Invoices</li>
         </ul>

        <!-- <div class="d-flex align-items-center"> -->
            <!-- <div class="d-flex align-items-center gap-6"> -->
                <!-- <img src="../../../../../assets/media/icons/ph_user-circle-plus-light.svg" class="top-bar-main-icon"/>
                <img src="../../../../../assets/media/icons/fluent_info-24-regular.svg" class="top-bar-main-icon"/>
                <img src="../../../../../assets/media/icons/heroicons-outline_template.svg" class="top-bar-main-icon"/> -->
                <!-- <img src="../../../../../assets/media/icons/mi_notification.svg" class="top-bar-main-icon"/> -->
                <!-- <i class="flaticon-user icon-size"></i>
                <i class="flaticon-line-graph icon-size"></i>
                <header-notifications></header-notifications> -->
                    <!-- </div> -->
                    <!-- <user-menu></user-menu> -->
                    <!-- <a class="d-flex align-items-center link" routerLink="/app/main/quotes/new">
                <i class="bi bi-plus"></i>
                Create new quote
            </a> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>

<!--    <div-->
<!--        id="kt_app_sidebar"-->
<!--        class="app-sidebar flex-column"-->
<!--        data-kt-drawer="true"-->
<!--        data-kt-drawer-name="app-sidebar"-->
<!--        data-kt-drawer-activate="{default: false, md: true}"-->
<!--        data-kt-drawer-overlay="true"-->
<!--        data-kt-drawer-width="225px"-->
<!--        data-kt-drawer-direction="start"-->
<!--        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">-->
        <!--begin::Logo-->
<!--        <div class="app-sidebar-logo px-6" id="kt_app_sidebar_logo">-->
            <!--begin::Logo image-->
<!--            <default-brand></default-brand>-->
            <!--end::Logo image-->
            <!--begin::Sidebar toggle-->
<!--            <div-->
<!--                *ngIf="appSession.theme.baseSettings.menu.allowAsideMinimizing"-->
<!--                id="kt_app_sidebar_toggle"-->
<!--                class="-->
<!--                    app-sidebar-toggle-->
<!--                    btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary-->
<!--                    body-bg-->
<!--                    h-30px-->
<!--                    w-30px-->
<!--                    position-absolute-->
<!--                    top-50-->
<!--                    start-100-->
<!--                    translate-middle-->
<!--                    rotate-->
<!--                "-->
<!--                data-kt-toggle="true"-->
<!--                data-kt-toggle-state="active"-->
<!--                data-kt-toggle-target="body"-->
<!--                data-kt-toggle-name="app-sidebar-minimize"-->
<!--            >-->
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
<!--                <span class="svg-icon svg-icon-2 rotate-180">-->
<!--                    <svg-->
<!--                        width="24"-->
<!--                        height="24"-->
<!--                        viewBox="0 0 24 24"-->
<!--                        fill="none"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                    >-->
<!--                        <path-->
<!--                            opacity="0.5"-->
<!--                            d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"-->
<!--                            fill="currentColor"-->
<!--                        ></path>-->
<!--                        <path-->
<!--                            d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"-->
<!--                            fill="currentColor"-->
<!--                        ></path>-->
<!--                    </svg>-->
<!--                </span>-->
                <!--end::Svg Icon-->
<!--            </div>-->
            <!--end::Sidebar toggle-->
<!--        </div>-->
        <!--end::Logo-->
        <!--begin::sidebar menu-->
<!--        <div class="app-sidebar-menu overflow-hidden flex-column-fluid">-->
            <!--begin::Menu wrapper-->
<!--            <div-->
<!--                id="kt_app_sidebar_menu_wrapper"-->
<!--                class="app-sidebar-wrapper hover-scroll-overlay-y my-5"-->
<!--                data-kt-scroll="true"-->
<!--                data-kt-scroll-activate="true"-->
<!--                data-kt-scroll-height="auto"-->
<!--                data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"-->
<!--                data-kt-scroll-wrappers="#kt_app_sidebar_menu"-->
<!--                data-kt-scroll-offset="5px"-->
<!--                data-kt-scroll-save-state="true"-->
<!--            >-->
<!--                <side-bar-menu></side-bar-menu>-->
<!--            </div>-->
            <!--end::Menu wrapper-->
<!--        </div>-->
        <!--end::sidebar menu-->
<!--    </div>-->


</div>
<div class="route-page-content">
    <router-outlet></router-outlet>
</div>
<!-- <ng-template #topMenuChildren let-item="item">
    <ul>
        <li *ngFor="let child of item.children" [routerLink]="child.route">
            <i class="me-3 bi " [class]="'bi-' + child.icon"></i>
            {{child.title}}
        </li>
    </ul>
</ng-template> -->
