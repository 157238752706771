import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuoteSettingService } from '@app/admin/settings-app/quote-setting/quote-setting.service';
import { CloudinaryCoverUploadSignal, TemplateSignal, TemplateViewTypesEnum } from '@app/main/quotes/models';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BusinessDomainDto, ClientDto, QuoteTransactionType } from '@shared/service-proxies/service-proxies';
import { environment } from 'environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MenuItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
    selector: 'app-cover',
    templateUrl: './cover.component.html',
    styleUrls: ['./cover.component.scss'],
})
export class CoverComponent extends AppComponentBase implements OnInit {
    @Input() businessDetails;
    showUpload:boolean = false
    @Input() selectedImg;
    @Input() selectedCoverImage;
    @Input() isScale = false;
    @Input() templateInfo;
    @Input() selectedTemplate;
    @Input() quoteName: string;
    @Input() client: ClientDto;
    @Input() clients: ClientDto[] = [];
    @Input() fromQuote = false;
    @Input() quoteType
    @Input() companyInfo:BusinessDomainDto;
    items: MenuItem[] | undefined;

    @Input() isPreview: boolean = true;

    @Input() isTemplateType: TemplateViewTypesEnum = TemplateViewTypesEnum.StaticTemplate;
    templateTypes = TemplateViewTypesEnum;
    quoteTranscationTypes = QuoteTransactionType

    @Output() signal = new EventEmitter<TemplateSignal>();

    @ViewChild('quoteNameField') quoteNameField: ElementRef<HTMLInputElement>;
    @ViewChild('uploadFilesField') uploadFilesField!: ElementRef<HTMLInputElement>;
    @ViewChild('templatePickerModal', { static: true }) modal: ModalDirective;

    allowedFileTypes = ['.jpg', '.jpeg', '.png'];
    editClient = false;
    showQuoteEditIcon = false;
    editQuoteName = false;

    editCompanyName = false;
    showCompanyEditIcon = false;

    editPhone = false;
    showPhoneEditIcon = false;

    editEmail = false;
    showEmailEditIcon = false;

    editAddress = false;
    showAddressEditIcon = false;

    baseUrl = environment.remoteServiceBaseUrl;
    loading: boolean = true;
    clientDDLTooltip: string = ""

    colors: string[] = ['#00C875', '#DF2E49', '#FDAB3D', '#326BF6'];
    previewDate:Date = new Date();
    tooltipOptions = {
        showDelay: 150,
        tooltipPosition: 'top',
        disabled:true
    };

    constructor(injector: Injector) {
        super(injector);
        this.getRandomColor()
    }

    getInitials(name) {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase());
        return initials.join('');
    }

    textMaxWidth(): string {
        const maxWidth = this.isMobileDevice() ? '100px' : '200px'

        switch (this.isTemplateType) {
            case TemplateViewTypesEnum.PreviewTemplate:
                return 'max-width: 35vw';
            case TemplateViewTypesEnum.StaticTemplate:
                return `max-width: ${maxWidth}`;
            case TemplateViewTypesEnum.CreateTemplate:
                return `max-width: ${maxWidth}`;
        }
    }

    ngOnInit(): void {
        if (!this.quoteName && this.isTemplateType == TemplateViewTypesEnum.CreateTemplate) {
            this.editQuoteName = true;
        }
        if (!this.templateInfo?.name && this.isTemplateType == TemplateViewTypesEnum.CreateTemplate) {
            this.editCompanyName = true;
        }
        if (!this.templateInfo?.address && this.isTemplateType == TemplateViewTypesEnum.CreateTemplate) {
            this.editAddress = true;
        }
        if (!this.templateInfo?.phone && this.isTemplateType == TemplateViewTypesEnum.CreateTemplate) {
            this.editPhone = true;
        }
        else if (!this.templateInfo?.email && this.isTemplateType == TemplateViewTypesEnum.CreateTemplate) {
            this.editPhone = true;
        }

        if(this.quoteType == this.quoteTranscationTypes.Template){
            this.tooltipOptions.disabled = false;
        }
        this.clientDDLTooltip =  this.quoteType === this.quoteTranscationTypes.Template ? "Update your company's details in the 'Company Info' section." : "";

        this.getRandomColor();
    }

    show(): void {
        this.modal.show();
    }

    save() {
        this.close();
    }

    close() {
        this.modal.hide();
    }

    removedCoverPhoto() {
        this.selectedImg = null;
        this.signal.emit({ key: 'upload', value: null });
    }

    onSetValue(key: string): void {
        if (this.quoteName && key == 'quoteName') {
            this.editQuoteName = false;
            this.signal.emit({ key, value: this.quoteName });
        } else {
            const value = this.templateInfo[key];
            if (!value) return;

            this.editCompanyName = false;
            this.editAddress = false;
            this.editPhone = false;
            this.editEmail = false;
            this.signal.emit({ key, value: this.templateInfo[key] });
        }
    }

    onDrop(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        this.signal.emit({ key: 'upload', value: file });
    }

    onDragOver(event: any): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: any): void {
        event.preventDefault();
        event.stopPropagation();
    }

    fileBrowseHandler(target: HTMLInputElement): void {
        const file = target.files.item(0);
        this.signal.emit({ key: 'upload', value: file });
    }

    onUploadFromComputer(): void {
        this.uploadFilesField.nativeElement.value = '';
        this.uploadFilesField.nativeElement.click();
    }

    onClientSelectionChange(ev: DropdownChangeEvent): void {
        if (ev.value) {
            this.editClient = false;
        }
        this.client = ev.value;
        this.signal.emit({ key: 'clientId', value: this.client });
    }

    onCreateNewClient(clientId: string): void {
        this.signal.emit({ key: 'newClient', value: clientId });
    }

    getRandomColor(): void {
        for (let i = 0; i < this.clients?.length; i++) {
            const colorIndex = i % 4; // Repeat color every 4 items
            this.clients[i]['avatarBg'] = this.colors[colorIndex];
          }
    }
    onCloudinaryCoverSignal(event:CloudinaryCoverUploadSignal){
        this.selectedImg= {
            imgSrc:event.url,
            uniqueFileName: event.url,
        };
        this.signal.emit({ key: 'imageName', value: event.url });
    }

    private isMobileDevice() {
        return KTUtil.isMobileDevice();
    }
}
