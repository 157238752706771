import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    IProjectCustomPrice,
    IQuotePage,
    QuoteItems,
    SectionItem,
    TemplateViewTypesEnum,
} from '@app/main/quotes/models';
import { PriceOptionsService } from '@app/main/quotes/price-options.service';
import { TaxService } from '@app/shared/services/tax.service';
import { TaxListDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-price-list-new',
    templateUrl: './price-list-new.component.html',
    styleUrls: ['./price-list-new.component.scss'],
})
export class PriceListNewComponent implements OnInit, OnDestroy {
    @Input() isTemplateType: TemplateViewTypesEnum = TemplateViewTypesEnum.StaticTemplate;
    templateTypes = TemplateViewTypesEnum;
    @Input() templateInfo;

    @Input() data: IQuotePage;
    @Input() title: string;
    @Input() pageNo: number;
    taxes: TaxListDto[] = [];

    cost: QuoteItems;
    customPrice: IProjectCustomPrice;
    isApplyDiscount = false;
    columnsVisibility = {
        quantity: true,
        price: true,
        tax: true,
    };

    isQuantityEnabled$ = this._priceOptionsService.isQuantityEnabled$;
    isPriceEnabled$ = this._priceOptionsService.isPriceEnabled$;
    isTaxEnabled$ = this._priceOptionsService.isTaxEnabled$;
    isLineTotalEnabled$ = this._priceOptionsService.isLineTotalEnabled$;
    projectCostVisibilityControl$ = this._priceOptionsService.projectCostVisibilityControl$;

    constructor(private _taxService: TaxService, private readonly _priceOptionsService: PriceOptionsService) {}

    ngOnInit(): void {
        console.log(this.data);
        if (this.data) {
            this.fetchAllTaxes();
        }
    }

    private fetchAllTaxes(): void {
        const pageOptions = {
            currentPage: 1,
            itemsPerPage: 10,
        };

        this._taxService.getTaxAll(pageOptions)?.subscribe({
            next: (resp) => {
                this.taxes = resp.result.items;
                if (this.data.jsonValue) {
                    this.populatePage();
                }
            },
        });
    }

    private populatePage(): void {
        const {
            DiscountValue,
            PriceList,
            IsHiddenLinePrice,
            IsHiddenLineTax,
            IsHiddenQuantity,
            IsCustomPrice,
            CustomPrice,
            TaxId,
        } = this.data.jsonValue;

        if (PriceList.length > 0) {
            this.cost = {
                sections: [],
                discount: DiscountValue,
            };

            this.columnsVisibility = {
                quantity: !IsHiddenQuantity,
                price: !IsHiddenLinePrice,
                tax: !IsHiddenLineTax,
            };

            for (let section of PriceList) {
                this.cost.sections.push({
                    title: section.Section,
                    items: section.Prices.map((rec) => {
                        const tax = this.taxes.find((tax) => tax.id === rec?.Tax);
                        const item: SectionItem = {
                            id: rec.id,
                            item: rec.ItemName,
                            quantity: rec.Quantity,
                            price: rec.Price,
                            tax,
                            description: rec.Description,
                            editable: rec.editable,
                        };

                        return item;
                    }),
                });
            }

            if (DiscountValue > 0) {
                this.isApplyDiscount = true;
            }

            if (IsCustomPrice) {
                const tax = this.taxes.find((tax) => tax.id === TaxId);

                this.customPrice = {
                    subTotal: CustomPrice,
                    tax: tax?.value || 0,
                    taxId: TaxId,
                };
            }
        }
    }

    getItemTotalTax(): number {
        if (this.customPrice) {
            return this.customPrice.tax;
        }

        let taxTotal = 0;

        if (this.cost) {
            for (let section of this.cost.sections) {
                const sectionTaxTotal = section.items.reduce((total, item) => (item.tax?.value || 0) + total, 0);
                taxTotal += sectionTaxTotal;
            }
        }

        return taxTotal || 0;
    }

    ngOnDestroy(): void {}
}
