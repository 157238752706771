import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import {  FormGroup, FormControl, Validators, FormBuilder  } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EmailTemplatesServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-create-or-edit-email-template',
  templateUrl: './create-or-edit-email-template.component.html',
  styleUrls: ['./create-or-edit-email-template.component.css']
})
export class CreateOrEditEmailTemplateComponent extends AppComponentBase implements OnInit {
  @ViewChild('createOrEditEmailTemplateComponent', { static: true }) modal: ModalDirective;

  @Output() onModalSave: EventEmitter<any> = new EventEmitter<any>();

  emailTemplateForm: FormGroup;
  emailTemplate: any;

  // loaders
  saving = false;

  isEditMode = false;
  submitAttempt = false;
  selectedType:any;
  types:any;
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private emailTemplateService: EmailTemplatesServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.emailTemplateForm = this.formBuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required],
      type: ['', Validators.required],
    });
    this.types=[{name:"Quotes"},{name:"Invoice"},{name:"Material Order"},{name:"Change Order"},{name:"Work Order"}]
  }

  saveEmailTemplate() {
    this.submitAttempt = true;

    if (!this.emailTemplateForm.valid) {
      return;
    }

    this.saving = true;

    const payload = {
      ...this.emailTemplateForm.value,
      ...(this.isEditMode && { id: this.emailTemplate?.id })
    };


    if (!this.isEditMode) {
      this.emailTemplateService.createOrEdit(payload).subscribe(
        response => {
          this.saving = false;
          this.notify.info(this.l('SavedSuccessfully'));
          this.onModalSave.emit(response);
          this.closeModal();
        }, error => {
          this.saving = false;
          this.notify.error(`Error: ${error?.message}`);
        }
      );
    } else {
      this.emailTemplateService.createOrEdit(payload).subscribe(
        response => {
          this.saving = false;
          this.notify.info(this.l('Updated successfully'));
          this.onModalSave.emit(response);
          this.closeModal();
        }, error => {
          this.saving = false;
          this.notify.error(`Error: ${error?.message}`);
        }
      );
    }
  }

  showModal(emailTemplate?: any): void {
    this.emailTemplateForm.reset();
    this.submitAttempt = false;

    if (emailTemplate) {
      this.isEditMode = true;
      this.emailTemplate = emailTemplate;

      this.emailTemplateForm.patchValue(emailTemplate);
    } else {
      this.isEditMode = false;
    }

    this.modal.show();
  }

  onFocus(): void {
    document.getElementById('clientName')?.focus();
  }

  closeModal(): void {
    this.modal.hide();
  }
}
