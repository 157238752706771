<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">
<head>
    <style>
        body {
            font-family: 'qcfont';
            position:relative;
        }

        .cover {
            position: absolute;
            top: 64mm;
            bottom: 100mm;
            left: 35mm;
            right: 0;
        }
    </style>
</head>

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex p-0">
        <div class="d-flex-column flex-1" style="border-top-left-radius: 20px;"
            [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></div>
        <div class="d-flex-column flex-5">
            <div class="d-flex-row flex-7"
                [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}">
                <div class="d-flex-column flex-1">
                    <div class="d-flex flex-2 v-center">
                        <div style="font-size: 11mm; padding: 14mm 5mm 5mm 5mm; font-family: qc-DaysOne-Regular; width:100%; text-align: left; line-height: 1.42; -webkit-box-orient: vertical; display: -webkit-box; max-width: 100%; height: 215px; overflow-wrap: break-word; -webkit-line-clamp: 3; text-overflow: ellipsis; overflow: hidden; box-sizing: border-box; word-break: break-word;">
                            {{ templateInfo?.description1 }}</div>
                    </div>
                    <div class="d-flex flex-5">

                    </div>
                    <div class="d-flex-column flex-2 d-flex flex-column align-items-start" style="margin-left:5mm;">
                        <div style="padding:3mm; min-height: 50mm; color: #000000;">
                            <div style="font-size: 7mm; font-weight: bold; padding-bottom: 2mm; text-align: left;">
                                Prepared for:
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                Client Name
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                Address
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="address-ellipsis">
                                City, State, Zip Code
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex-column flex-1" style="background:white; border-top-right-radius: 20px;">
                    <div class="d-flex flex-2">
                        <div class="b-logo w-100" style="border-top-right-radius: 20px;" [ngStyle]="{
                            'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                            'var(--b-image-url)', 
                            'background-position': 'center',
                            'background-size': 'contain'
                        }"></div>
                    </div>
                    <div class="d-flex flex-5">

                    </div>
                    <div class="d-flex-column flex-2 m-r-254" style="text-align:left;">
                        <div style="padding:3mm; min-height: 50mm; color: #000000;">
                            <div style="font-size: 7mm; font-weight: bold; padding-bottom: 2mm;">
                                Presented by:
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                {{ templateInfo?.userName }}
                            </div>
                            <div style="font-size: 5mm; text-align: left;" class="paragpraph-ellipsis">
                                {{ templateInfo?.usePhoneNumer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex cover">
                <div class="flex-2 b-q-logo" [ngStyle]="{
                    'background-image': selectedCoverImage?.uniqueFileName ? 'url(' + selectedCoverImage?.imgSrc + ')' :
                    'var(--b-q-image-url)',
                    'background-size': 'cover',
                    'background-position': 'center',
                }"></div>
            </div>
        </div>
    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>
</body>

</html>