<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">
<head>
    <style>
        body {
            font-family: 'qcfont';
            position: relative;
        }

        .footer {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        .rightline {
            position: absolute;
            right: 7mm;
            bottom: 1mm;
            top: 0;
            width: 28mm;
            background-color: var(--bs-primary);
            border: none;
            z-index: 100;
        }

        .leftline {
            position: absolute;
            width: 2px;
            background: var(--bs-primary);
            top: 0;
            bottom: 0;
            left: 26mm;
        }
    </style>
</head>

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex">
        <div class="flex-1 d-flex d-flex-column text-secondary">
            <div class="d-flex-column flex-10">
                <div class="d-flex d-flex-row flex-1">
                    <div style="position: absolute; height: 91px; left: 102mm; right: 7mm; bottom: 0; top: 0;"
                        [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}">
                    </div>
                    <div style="position: absolute; height: 2px; left: 0; right: 7mm; bottom: 0; top: 24mm;"
                        [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}">
                    </div>
                </div>
                <div class="d-flex d-flex-row flex-3">
                    <div class="flex-2 b-logo p-l-254 p-r-254" [ngStyle]="{
                        'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                        'var(--b-image-url)', 
                        'background-position': 'center',
                        'background-size': 'contain'
                    }"></div>
                </div>
                <div class="d-flex d-flex-row flex-3" style="margin-left: 4.5cm; margin-right: 4cm;">
                    <div style="font-size: 16mm; color:black; font-weight: bold; font-family: qc-RussoOne-Regular;" class="title-ellipsis-2">{{ templateInfo?.description1 }}</div>
                </div>
                <div class="d-flex d-flex-row flex-4"
                    style="margin-left: 4.5cm; margin-right:4cm; font-size: 14mm; justify-content: space-evenly; color: #000000;">
                    <div style="position: absolute; width: 1mm; height:74mm; left: 34mm; background:black;">
                        <div
                            style="background: black; width: 3mm; height: 3mm; position: absolute; left: -1mm; top: -1mm;">
                        </div>
                        <div
                            style="background: black; width: 3mm; height: 3mm; position: absolute; left: -1mm; bottom: -1mm;">
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="d-flex-row" style="font-size: 7mm; font-weight: bold; text-align: left;">
                            Prepared for:
                        </div>
                        <div style="font-size: 6mm; text-align: left;" class="paragpraph-ellipsis">
                            Client Name
                        </div>
                        <div style="font-size: 6mm; text-align: left;" class="paragpraph-ellipsis">
                            Address
                        </div>
                        <div style="font-size: 6mm; text-align: left;" class="address-ellipsis">
                            City, State, Zip Code
                        </div>

                        <div class="d-flex-row" style="font-size: 7mm; margin-top: 7mm; font-weight: bold; text-align: left;">
                            Presented by:
                        </div>
                        <div style="font-size: 6mm; text-align: left;" class="paragpraph-ellipsis">
                            {{ templateInfo?.userName }}
                        </div>
                        <div style="font-size: 6mm; text-align: left;" class="paragpraph-ellipsis">
                            {{ templateInfo?.usePhoneNumer }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightline" style="height: 94.6%; border-radius: 0;"
                [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></div>
            <div class="leftline"
                [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></div>
        </div>
    </div>
    <app-template-footer [templateInfo]="templateInfo" [transparentBackground]="true"></app-template-footer>
</body>

</html>