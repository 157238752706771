import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProjectCostVisibilityControl } from './models';

@Injectable({
    providedIn: 'root',
})
export class PriceOptionsService {
    private _isQuantityEnabledSubject = new BehaviorSubject<boolean>(true);
    private _isPriceEnabledSubject = new BehaviorSubject<boolean>(true);
    private _isTaxEnabledSubject = new BehaviorSubject<boolean>(true);
    private _isUnitEnabledSubject = new BehaviorSubject<boolean>(true);
    private _isLineTotalEnabledSubject = new BehaviorSubject<boolean>(true);
    private _projectCostVisibilityControl = new BehaviorSubject<IProjectCostVisibilityControl>(null);
    
    isQuantityEnabled$ = this._isQuantityEnabledSubject.asObservable();
    isPriceEnabled$ = this._isPriceEnabledSubject.asObservable();
    isTaxEnabled$ = this._isTaxEnabledSubject.asObservable();
    isUnitEnabled$ = this._isUnitEnabledSubject.asObservable();
    isLineTotalEnabled$ = this._isLineTotalEnabledSubject.asObservable();
    projectCostVisibilityControl$ = this._projectCostVisibilityControl.asObservable();

    set isQuantityEnabled(newFlag: boolean) {
        this._isQuantityEnabledSubject.next(newFlag);
    }
    set isPriceEnabled(newFlag: boolean) {
        this._isPriceEnabledSubject.next(newFlag);
    }
    set isTaxEnabled(newFlag: boolean) {
        this._isTaxEnabledSubject.next(newFlag);
    }
    set isUnitEnabled(newFlag: boolean) {
        this._isUnitEnabledSubject.next(newFlag);
    }
    set isLineTotalEnabled(newFlag: boolean) {
        this._isLineTotalEnabledSubject.next(newFlag);
    }
    set projectCostVisibilityControl(projectCostVisibilityControl: IProjectCostVisibilityControl) {
        this._projectCostVisibilityControl.next(projectCostVisibilityControl);
    }
}
