<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">
<head>
    <style>
        .cover {
            position: absolute;
            top: 64mm;
            left: 25.4mm;
            right: 25.4mm;
            height: 130mm;
        }
    </style>
</head>

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex p-0">
        <div class="flex-1 d-flex-column bg-primary" style="border-top-left-radius: 20px; border-top-right-radius: 20px;">
            <div class="d-flex-row flex-7 text-secondary" style="border-top-right-radius: 20px; border-top-left-radius: 20px;">
                <div class="d-flex-column flex-1" style="border-top-left-radius: 20px !important;"
                    [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}">
                    <div class="d-flex flex-2 m-l-254 d-flex flex-column align-items-start">
                        <div style="font-size: 11mm; padding: 5mm; font-family: qc-RussoOne-Regular; text-align: left;  line-height: 1.42; -webkit-box-orient: vertical; display: -webkit-box; max-width: 100%; height: 200px; overflow-wrap: break-word; -webkit-line-clamp: 3; text-overflow: ellipsis; overflow: hidden; box-sizing: border-box; word-break: break-word;">{{ templateInfo?.description1 }}
                        </div>
                    </div>
                    <div class="d-flex flex-5">

                    </div>
                    <div class="d-flex-column flex-2 m-l-254 d-flex flex-column align-items-start">
                        <div style="min-height: 50mm;">
                            <div style="font-size: 7mm; font-weight: bold; padding-bottom: 2mm; text-align: left;">
                                Prepared for:
                            </div>
                            <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                                Client Name
                            </div>
                            <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                                Address
                            </div>
                            <div style="font-size: 5mm;" class="d-flex flex-column align-items-start address-ellipsis">
                                City, State, Zip Code
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex-column flex-1" style="background:white; border-top-right-radius: 20px;">
                    <div class="d-flex flex-2">
                        <div class="b-logo w-100" style="border-top-right-radius: 20px !important;" [ngStyle]="{
                        'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                        'var(--b-image-url)', 
                        'background-position': 'center' 
                    }"></div>
                    </div>
                    <div class="d-flex flex-5">

                    </div>
                    <div class="d-flex-column flex-2 m-r-254 d-flex flex-column align-items-start" style="text-align:left; margin-left: 117px;"
                        [ngStyle]="{'color': templateInfo?.templateColor ? templateInfo?.templateColor : 'text-primary'}">
                        <div style="min-height: 50mm;">
                            <div style="font-size: 7mm; font-weight: bold; padding-bottom: 2mm;">
                                Presented by:
                            </div>
                            <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                                {{ templateInfo?.userName }}
                            </div>
                            <div style="font-size: 5mm;" class="d-flex flex-column align-items-start paragpraph-ellipsis">
                                {{ templateInfo?.usePhoneNumer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex cover">
                <div class="flex-2 b-q-logo" [ngStyle]="{
                'background-image': selectedCoverImage?.uniqueFileName ? 'url(' + selectedCoverImage?.imgSrc + ')' :
                'var(--b-q-image-url)' 
            }"></div>
            </div>
        </div>
    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>
</body>

</html>