import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common'; // Import CommonModule for NgStyle

@Component({
    selector: 'app-svg-icon',
    templateUrl: './svg-icon.component.html',
    standalone: true,
    styleUrls: ['./svg-icon.component.scss'],
    imports: [CommonModule]
})
export class SvgIconComponent implements OnInit {
    @Input() icon!: string;
    @Input() size: string = '24';

    svgContent!: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        this.loadSvg();
    }

    loadSvg(): void {
        const svgUrl = `assets/icons/${this.icon}.svg`;
        this.http.get(svgUrl, { responseType: 'text' }).subscribe(
            (svgData) => {
                this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svgData);
            },
            (error) => {
                console.error(`Error loading SVG: ${error}`);
            }
        );
    }
}
