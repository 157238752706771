<div class="signature-pad">
    <div *ngIf="showImage">
        <img
            *ngIf="image"
            [width]="width"
            [height]="height"
            class="object-fit-cover signature-img"
            src="{{ baseUrl }}/{{ image }}"
            alt="Signature Image"
        />
    </div>

    <div class="image" *ngIf="!showImage">
        <img
            *ngIf="image; else drawingCanvas"
            [width]="width"
            [height]="height"
            class="object-fit-cover"
            src="{{ baseUrl }}/{{ image }}"
            alt="Signature Image signature-img"
        />
    </div>

    <ng-template #drawingCanvas>
        <canvas
            #signPad
            [width]="width"
            [height]="height"
            (mousedown)="onMouseDown($event)"
            (mousemove)="onMouseMove($event)"
            (touchmove)="onMouseMove($event)"
            (touchstart)="onMouseDown($event)"
            (mouseup)="saveSignature()"
            style="width: 100%"
        ></canvas>
    </ng-template>

    <div class="d-flex mt-2">
        <button *ngIf="!isHideClearButton" class="btn btn-ext btn-outline-primary btn-outline-primary-ext" (click)="clearSignature()">Clear</button>
        <button *ngIf="!isHideSaveButton && !image" class="btn btn-ext btn-primary-ext btn-primary me-3" (click)="saveSignature()">Save</button>
    </div>
</div>
