import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPrintModule } from 'ngx-print';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
    declarations: [
    ],
    imports: [CommonModule, NgxPrintModule, ProgressSpinnerModule],
    exports: [
    ],
})
export class TemplateOneModule {}
