<div class="sign-quote-container">
    <div class="header">
        <div class="back">
            <a routerLink="/">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.3789 19.25L5.62891 12.5L12.3789 5.75M6.56641 12.5H20.2539"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </a>
        </div>

        <div class="title">&nbsp;</div>

        <div class="action"></div>
    </div>

    <div class="header-actions">
        <div class="sign-in-approve">
            <button class="btn btn-outline-primary" (click)="onSignQuoteTemplate()">Sign and Approve</button>
        </div>

        <div class="more-action">
            <p-dropdown
                (onChange)="onSelectionChange($event)"
                [options]="options"
                optionLabel="name"
                [showClear]="false"
                placeholder="More Actions"
            >
                <ng-template pTemplate="selectedItem">
                    <div class="d-flex align-items-center gap-2" *ngIf="selectedOption">
                        <div>{{ selectedOption.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center gap-2">
                        <img
                            *ngIf="item.value === 0"
                            src="../../../../assets/media/icons/download-blue.png"
                            alt="Download Icon"
                        />
                        <img
                            *ngIf="item.value === 1"
                            src="../../../../assets/media/icons/printer-blue.png"
                            alt="View & Print PDF Icon"
                        />
                        <div>{{ item.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
    <div class="content container-fluid">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-2"></div>
            <div class="col-12 col-md-12 col-lg-8 content">
                <iframe class="pdf-iframe" [src]="pdfUrlAsSafeResource" width="100%" height="100%"></iframe>
            </div>
            <div class="col-12 col-md-12 col-lg-2"></div>
        </div>
    </div>

    <div class="view-footer">
        <ul class="view-footer__items">
            <li class="view-footer__items__item">
                <button
                    class="btn btn-outline-primary"
                    (click)="onDownloadQuote()"
                    [disabled]="disableDownloadQuoteBtn"
                >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.50249 9.37129V3.47754M7.50249 9.37129C7.08981 9.37129 6.31875 8.1959 6.02905 7.89785M7.50249 9.37129C7.91517 9.37129 8.68625 8.1959 8.97593 7.89785"
                            stroke="#326BF6"
                            stroke-width="0.884063"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.2175 10.5498C12.2175 12.0126 11.9122 12.3179 10.4494 12.3179H4.55563C3.0928 12.3179 2.78751 12.0126 2.78751 10.5498"
                            stroke="#326BF6"
                            stroke-width="0.884063"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    {{ disableDownloadQuoteBtn ? 'Downloading' : 'Download' }}

                    <p-progressSpinner
                        *ngIf="disableDownloadQuoteBtn"
                        class="progress-spinner-height ms-3"
                        strokeWidth="4"
                    ></p-progressSpinner>
                </button>
            </li>

            <li class="view-footer__items__item">
                <button class="btn btn-outline-primary" (click)="onSignQuoteTemplate()">Sign Quote</button>
            </li>
        </ul>
    </div>
</div>
