import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as pako from 'pako';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

    
  baseUrl = environment.remoteServiceBaseUrl;
  constructor(private http: HttpClient) {}

  async compressAndUploadFile(file: File): Promise<void> {
    // Read the file as ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();
    const compressed = pako.gzip(new Uint8Array(arrayBuffer));

    // Convert compressed data to Blob
    const compressedBlob = new Blob([compressed], { type: 'application/octet-stream' });

    // Create FormData
    const formData = new FormData();
    formData.append('file', compressedBlob, file.name + '.gz'); // Add .gz extension

    // Send the file to the server
    const uploadUrl = `${this.baseUrl}/upload-media-library`; // Update with your API endpoint
    this.http.post(uploadUrl, formData).subscribe({
      next: () => abp.message.success('File uploaded successfully'),
      error: (err) => console.error('Upload failed', err)
    });
  }
}