import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { GeneratePdfService } from '@app/shared/services/generate-pdf.service';
import { QuoteImageService } from '@app/shared/services/quote-image.service';
import { IQuoteSingOutputDto } from '@shared/service-proxies/service-proxies';
import saveAs from 'file-saver';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { SignQuoteFormComponent } from '../components/sign-quote-form/sign-quote-form.component';

@Component({
    selector: 'app-sign-quote',
    templateUrl: './sign-quote.component.html',
    styleUrls: ['./sign-quote.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ProgressSpinnerModule, DropdownModule, RouterModule],
    providers: [DialogService],
})
export class SignQuoteComponent implements OnInit {
    key: string;
    pdfUrlAsSafeResource: SafeResourceUrl;
    signedByFC = new FormControl('', Validators.required);
    disableDownloadQuoteBtn = false;
    disableSignQuoteBtn = false;
    quoteSignatureData: IQuoteSingOutputDto;
    selectedOption: any | undefined = { name: 'More Actions', value: 9 };
    options = [
        { name: 'Download PDF', value: 0 },
        { name: 'View & Print PDF', value: 1 },
    ];
    constructor(
        private route: ActivatedRoute,
        private _pdfService: GeneratePdfService,
        private _quoteImageService: QuoteImageService,
        private _domSanitizerService: DomSanitizer,
        private _dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.key = this.route.snapshot.queryParamMap.get('key');
        this.getQuotePdf();
    }

    getQuotePdf(): void {
        this._pdfService.viewEmailedQuotePdf(this.key).subscribe({
            next: (resp: any) => {
                this.quoteSignatureData = resp.result;
                this.pdfUrlAsSafeResource = this._domSanitizerService.bypassSecurityTrustResourceUrl(
                    this.quoteSignatureData.fileName
                );
                this.disableSignQuoteBtn = false;
            },
        });
    }

    onSignQuoteTemplate(): void {
        const dialog = this._dialogService.open(SignQuoteFormComponent, {
            header: 'Sign Quote',
            width: '458px',
            data: {
                quoteSignatureData: this.quoteSignatureData,
            },
        });
        dialog;
        dialog.onClose.subscribe((resp) => {
            if (resp) {
                this.getQuotePdf();
            }
            // this.isInProcess = true;
            // const { key, pageTitle } = resp;
            // this.onMakeNewPage(key, pageTitle);
        });
    }

    onSignQuote(): void {
        this.disableSignQuoteBtn = true;
        const file = this.getSignatureFileData();

        const payload = new FormData();
        payload.append('File', file);
        payload.append('key', this.key);
        payload.append('SignedNameClient', this.signedByFC.value);

        this._pdfService.uploadQuoteImageFileForSignClient(payload).subscribe({
            next: () => this.getQuotePdf(),
            error: () => (this.disableSignQuoteBtn = false),
        });
    }

    async onDownloadQuote() {
        this.disableDownloadQuoteBtn = true;
        const fileName = this.quoteSignatureData.fileName.split('/').pop();

        this._quoteImageService.downloadPdf('DownloadPDFQuoteFile', fileName).subscribe({
            next: (resp: Blob) => {
                const downloadURL = window.URL.createObjectURL(resp);
                saveAs(downloadURL, 'Quote.pdf');
                this.disableDownloadQuoteBtn = false;
            },
            error: () => (this.disableDownloadQuoteBtn = false),
        });
    }

    private getSignatureFileData(): File {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.fillText(this.signedByFC.value, 10, 40);
        const image = canvas.toDataURL('image/png');
        const file = this.dataURLtoFile(image, 'signature.jpg');
        return file;
    }

    private dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    onSelectionChange(ev: DropdownChangeEvent): void {
        switch (ev.value.value) {
            case 0:
                this.onDownloadQuote();
                break;
            case 1:
                break;

            default:
                break;
        }
    }
}
