import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { Template1Component } from './shared/common/business-templates/template1/template1.component';
import { InvoicePDFTemplateComponent } from './shared/common/invoice-pdf-template/invoice-pdf-template.component';
import { SectionTemplatesContainerComponent } from './shared/common/quote-templates/section-templates-container/section-templates-container.component';
import { SignQuoteComponent } from './shared/common/sign-quote/sign-quote/sign-quote.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { EmailTemplateComponent } from './email-template/email-template.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: '**',
                        redirectTo: 'notifications',
                    },
                ],
            },
            {
                path: 'sign-quote',
                component: SignQuoteComponent,
            },

            {
                path: 'viewQuote',
                loadChildren: () =>
                    import('app/shared/common/sign-quote/sign-quote-new/sign-quote-new.module').then(
                        (m) => m.SignQuoteNewModule
                    ),
            },
            {
                path: 'preview/quotes',
                loadChildren: () => import('app/preview/preview.module').then((m) => m.PreviewModule), //Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            // TODO => only for testing purposes
            {
                path: 'template1.html',
                component: Template1Component,
            },
            {
                path: 'section-templates-1.html',
                component: SectionTemplatesContainerComponent,
            },
            {
                path: 'invoice-pdf-template.html',
                component: InvoicePDFTemplateComponent,
            },
            // {
            //     path: 'app/main/email-template',
            //     component: EmailTemplateComponent,
            // },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
