import { ChangeDetectorRef, Component, Injector, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { IQuotePage, QuotePageKey } from '@app/main/quotes/models';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BusinessDto, BusinessServiceServiceProxy, ClientsDropdownDto, ClientServiceServiceProxy, CreateOrEditFolder, EntityDtoOfGuid, FolderDto, FolderServiceProxy, GalleryQuoteDto, GetClientInput, GetQuoteInput, IQuoteListDto, IQuoteStatusDto, MaterialListServiceProxy, MediaLibraryDto, MediaLibraryServiceProxy, PagedResultDtoOfQuoteListDto, QuoteChangeIsSharedDto, QuoteDetailsServiceServiceProxy, QuoteDetalKey, QuoteListDto, QuoteServiceServiceProxy, QuoteStatus, QuoteStatusDto, QuoteStatusServiceServiceProxy, UpdateQuoteDetailOrderInput, UpdateQuoteTemplateStatusDto, UpdateStatusIdByQuoteDto } from '@shared/service-proxies/service-proxies';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LazyLoadEvent, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { debounceTime, finalize, Observable, Subject } from 'rxjs';
import { CommonLookupModalComponent } from '../lookup/common-lookup-modal.component';
import { UploadEvent } from 'primeng/fileupload';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { HttpClient } from '@angular/common/http';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
  animations: [appModuleAnimation()],
})
export class MediaLibraryComponent extends AppComponentBase {

  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('paginator') paginator: Paginator;
  @ViewChild('signedInfoModal', { static: true }) modal: ModalDirective;
  uploadUrl: string;
  baseUrl = environment.remoteServiceBaseUrl;
  //filter modal
  clients: ClientsDropdownDto[] = [];
  statuses: IQuoteStatusDto[] = [];
  ranges: { [key: string]: [moment.Moment, moment.Moment] } = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
      ],
  };
  filterForm: FormGroup;
  //end filter modal
  advancedFiltersAreShown = false;
  filterText = '';
  onlyLockedUsers = false;
  openPopup = false;
  selectedView: 'list' | 'grid' = 'grid';
  actions: MenuItem[];
  itemsPages: IQuotePage[] = [];
  visible:boolean = false;
  allQuotesTemplates:any = [];
  selectedQuoteTemplate:any = {};
  QuoteTemplateSelected:boolean = false;
  searchText:string = "";
  private filterSubject: Subject<string> = new Subject<string>();
  //filter
  toggle: boolean = true;
  show: boolean = false;
  modalShow: boolean = false;
  templateInfo:any;
  companyInfo:any;
  activeIndex: number = 0;

  selectedFile: File | null = null;
  uploadedFileUri: string | null = null;
  galleryQuotes: GalleryQuoteDto[];
  selectedPage: IQuotePage;
  
  pages: IQuotePage[] = [];
  
  checked: boolean = false;
  scrollableTabs: any[] = [
    { title: "My Files", icon: '../../../assets/media/icons/uil_search.png',activeIndex: 0 },
    { title: "Web Address", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 1},
    { title: "Image Search", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 2},
    { title: "Google Drive", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 3},
    { title: "Dropbox", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 4},
    { title: "Shutterstock", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 5},
    { title: "iStock", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 6},
    { title: "Getty Images", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 7},
    { title: "Unsplash", icon: "../../../assets/media/icons/uil_search.png" ,activeIndex: 8}
  ];
    mediaLibraries:MediaLibraryDto[];

    assets: any[] = [];
    total: number = 241;
    rows: number = 40;
  
    isIdSelected: boolean = false;
    currentSection = 'template';
    quotePageTypes = QuotePageKey;
    isInProcess: boolean = false;
    menuItems = [
      { label: 'Rename', icon: 'pi pi-pencil', command: () => this.renameAsset() },
      { label: 'Download', icon: 'pi pi-download', command: () => this.downloadAsset() },
      { label: 'Move to Folder', icon: 'pi pi-folder', command: () => this.moveToFolder() },
      { label: 'Delete', icon: 'pi pi-trash', command: () => this.deleteAsset() },
    ];
    folder = new CreateOrEditFolder();
    folders: FolderDto[];
    draggedMedia: MediaLibraryDto;
    selectedMedias: any
  constructor(
    injector: Injector,
    public _impersonationService: ImpersonationService,
    private _quoteServiceProxy: QuoteServiceServiceProxy,
    private _MatrialListServiceServiceeProxy: MaterialListServiceProxy,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _quoteDetailService: QuoteDetailsServiceServiceProxy,
    private _dialogService: DialogService,
    private _clientService: ClientServiceServiceProxy,
    private _quoteStatusService: QuoteStatusServiceServiceProxy,
    private fb: FormBuilder,
    private _businessService: BusinessServiceServiceProxy,
    private config: PrimeNGConfig, private messageService: MessageService,private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private readonly _appSessionService: AppSessionService,
    private _mediaLibraryService : MediaLibraryServiceProxy,
    private _folderService : FolderServiceProxy,private sanitizer: DomSanitizer
) {
    super(injector);
    this.filterText = this._activatedRoute.snapshot.queryParams['filterText'] || '';
    this.uploadUrl = AppConsts.remoteServiceBaseUrl + '/Users/ImportFromExcel';

    this.actions = [];

    if (this.isGranted('Pages.Quote.Generate.Invoice')) {
        this.actions.push({ label: 'Generate Invoice', id: 'generate_invoice' });
    }

    this.actions.push({ label: 'Download Invoice', id: 'download_invoice' });

    if (this.isGranted('Pages.Quote.Delete')) {
        this.actions.push({ label: 'Delete Quote', id: 'generate_invoice' });
    }

    if (this.isGranted('Pages.Quote.Create')) {
        this.actions.push({ label: 'Duplicate Quote', id: 'generate_invoice' });
    }

    if (this.isGranted('Pages.Quote.Create')) {
        this.actions.push({ label: 'Generate Material Order', id: 'generate_invoice' });
    }
    if(this.isGranted('Pages.Quote.Create')){
        this.actions.push( { label: 'Generate Change Of Order', id: 'generate_invoice' });
    }
    if (this.isGranted('Pages.Quote.Create')) {
        this.actions.push({ label: 'Generate Work Order', id: 'generate_invoice' });
    }
    if (this.isGranted('Pages.Quote.Create')) {
        this.actions.push({ label: 'Create Quote Template', id: 'generate_invoice' });
    }

    // Subscribe to filterSubject and call filterItems with debounce
    this.filterSubject.pipe(debounceTime(100)).subscribe(() => {
        this.getQuotes();
    });

    this.filterForm = this.fb.group({
        client: [null],
        dateRange: [null],
        statuses: [null]
    });
    this.getAllMediaLibrary();
}
onAddNewPage(): void {
    // const dialog = this._dialogService.open(, {
    //     header: 'Add Pages',
    //     width: '22%',
    //     styleClass: 'select-quote-page-type-dlg',
    // });

}  

get showListMenu(): boolean {
    return this.toggle;
}
onPageSelect(page: IQuotePage): void {
    this.isInProcess = true;
    for (const p of this.pages) {
        if (p == page) continue;

        p.showActionMenu = false;
        p.isEditing = false;
    }

    this.selectedPage = page;
    this.isIdSelected = true;
    this.scrollToId(page.depId);
}

scrollToId(depId: string) {
    this.isIdSelected = true;
    setTimeout(() => {
        const element = document.getElementById(depId);
        element?.scrollIntoView({ behavior: 'smooth' });
        this.currentSection = depId;
        this.isIdSelected = false;
        this.isInProcess = false;
    }, 300);
}

onSortPages(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);

    const pages = this.pages
        .filter((page) => page.id)
        .map((page, index) => {
            return new UpdateQuoteDetailOrderInput({
                id: page.id,
                order: index,
                quoteDetailKey: page.key,
            });
        });

    this._quoteDetailService.updateQuoteDetailOrders(pages).subscribe({
        next: () => {
            console.log('Quote pages order updated.');
        },
    });
}
customSortPredicate(index: number, item: CdkDrag<number>) {
    const approvalIndex = item.dropContainer.data.findIndex((detail) => detail.key === QuotePageKey.APPROVAL);
    if(index === 0)
    {
        return false;
    }
    else if(index === approvalIndex)
    {
        return false;
    }
    return true;
  }
  
onUpdatePageTitle(page: IQuotePage, title: string): void {
    page.isEditing = false;

    // const payload = new UpdateQuoteDetails();
    // payload.id = page.id;
    // payload.name = title;
    // payload.enabled = true;
    // payload.json = page.json;
    // this._quoteDetailService.updateQuoteDetails(payload).subscribe({
    //     next: (resp: QuoteDetailDto) => {
    //         page.name = resp.name
    //        const itempage = this.itemsPages.find(i=>i.id===page.id);
    //        if(itempage)
    //        itempage.name = resp.name;
    //     },
    // });
}

toggleCheck() {
    this.checked = !this.checked;
}

loadAssets() {
    this.assets = Array.from({ length: this.rows }, (_, i) => ({
      name: 'Project Brief.docx',
      type: 'Tenant',
      image: 'https://via.placeholder.com/150', // Replace with actual image
    }));
  }

  onPageChange(event: any) {
    console.log('Page change:', event);
  }

  renameAsset() {
    alert('Rename clicked');
  }

  downloadAsset() {
    alert('Download clicked');
  }

  moveToFolder() {
    alert('Move to folder clicked');
  }

  deleteAsset() {
    alert('Delete clicked');
  }

  onTabChange(event: any): void {
    this.activeIndex = event?.index;
    this.cdr.detectChanges();
  }
  onFileSelected(event: any): void {
    debugger
    this.selectedFile = event.target.files[0];
    this.uploadFile()
}

uploadFile(): Observable<any> {
  const formData = new FormData();

  // Append the file
  if (this.selectedFile) {
    const mimeType = this.selectedFile.type;
    formData.append('mimeType', mimeType);

    formData.append('file', this.selectedFile, this.selectedFile.name);
  }

  // Append other fields
  formData.append('tenantId', this._appSessionService.tenant.id.toString());
  formData.append('fileNameData', this.selectedFile.name);

  return this.http.post<any>(`${this.baseUrl}/upload-media-library`, formData);
}

onUpload(): void {

    this.uploadFile().subscribe(() => {
        abp.notify.success("Image uploaded successfully")
        this.getAllMediaLibrary(); 
    });
    
}

private getMediaType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'pdf':
            return 'application/pdf';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        default:
            return 'application/octet-stream'; // Default binary type
    }
}
saveFolder(){debugger
    this._folderService.createOrEdit(this.folder).subscribe(()=>{
        abp.notify.success("Record saved successfully")
    })
}

getAllFolders(){
    this._folderService.getAll().subscribe(result=>{
        this.folders = result;
    })
}


getAllMediaLibrary() {
  this._mediaLibraryService.getAll().subscribe(result => {
    this.mediaLibraries = result.map(media => {
        if (media.fileName && media.fileName.endsWith('.pdf')) {
            media.pdfUrlAsSafeResource = this.sanitizer.bypassSecurityTrustResourceUrl(media.blobUrl);
          }
          // Sanitize the URL if it's a video
          if (media.fileName && this.isVideo(media.fileName)) {
            media.videoUrlAsSafeResource = this.sanitizer.bypassSecurityTrustResourceUrl(media.blobUrl);
          }
          return media;
        });
      
  })
}

isVideo(fileName: string): boolean {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
  }

moveMediaToFolder(media: any, folder: any) {debugger
    const payload = { mediaId: media.id, folderId: folder.id };
    // this._mediaLibraryService.moveToFolder(payload).subscribe({
    //   next: () => {
    //     console.log('Media moved successfully.');
    //   },
    //   error: (err) => {
    //     console.error('Error moving media:', err);
  
    //     // Revert the changes in the UI if needed
    //   },
    // });
  }


onFilterKeyUp(value: string) {
    this.filterSubject.next(value);
}

clearFilterText(): void {
    this.filterText = "";
    this.filterSubject.next("");
}

toggleFilter() {
    this.toggle = !this.toggle;
}

showFilter(){
    this.show = !this.show;

    if(this.show == true){
        this.toggle = true;
    }

    if(!this.show){

        this.filterForm = this.fb.group({
            client: [null],
            dateRange: [null],
            statuses: [null]
        });
    }

    this.getQuotes();
}

clearClientFilter() {
    this.filterForm.get('client').setValue(null);
}

clearDateFilter() {
    this.filterForm.get('dateRange').setValue(null);
}

clearStatusFilter() {
    this.filterForm.get('statuses').setValue(null);
}

applyFilters() {
    this.getQuotes();
}

onShowModalHandler(): void {
    this.modal.show();
}

onCloseModalHandler(): void {
    this.modal.hide();
}

ngOnInit(): void {
    let toDay: Date = new Date();
    var subscriptionEndDate = new Date(this.appSession.tenant.subscriptionDateString);
    if (subscriptionEndDate < toDay) {
        this._router.navigate(['/account/select-edition'])
    }

    if (this.selectedView === 'grid') {
        this.primengTableHelper.defaultRecordsCountPerPage = 6;
    }
    this.getAllFolders();
}

ngAfterViewInit(): void {
    this.primengTableHelper.adjustScroll(this.dataTable);
    this.getQuotes();
    this.getAllTemplatesNames();
    this.getAllQuoteStatuses();
    this.getAllClients();
    this.getUserBusiness();
    this.getCompanyData();
    this.cdr.detectChanges();
}

getAllQuoteStatuses() {
    this._quoteStatusService.getAllQuoteStatus().subscribe({
        next: (statuses: QuoteStatusDto[]) => (this.statuses = statuses),
    });
}
openModel() {
    this.openPopup = true;
}
getQuotes(event?: LazyLoadEvent) {
    if (this.primengTableHelper.shouldResetPaging(event)) {
        this.paginator?.changePage(0);

        if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
            return;
        }
    }

    this.primengTableHelper.showLoadingIndicator();

    const filters = this.filterForm.value;

    this._quoteServiceProxy
        .getQuotes(
            new GetQuoteInput({
                filter: this.filterText,
                clientId: filters.client?.id,
                startDate: filters.dateRange? filters.dateRange.startDate : null,
                endDate: filters.dateRange? filters.dateRange.endDate : null,
                statuses: filters.statuses?.map((status) => status.id),
                sorting: this.primengTableHelper.getSorting(this.dataTable),
                maxResultCount: 100,
                skipCount: this.primengTableHelper.getSkipCount(this.paginator, event),
            })
        )
        .pipe(finalize(() => this.primengTableHelper.hideLoadingIndicator()))
        .subscribe((result: PagedResultDtoOfQuoteListDto) => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;

            const records = result.items.map((record) => {
                record['showStatusChangeDropdown'] = false;
                record['showQuoteActions'] = false;
                return record;
            });

            this.primengTableHelper.records = records;

            this.primengTableHelper.hideLoadingIndicator();
        });
}

reloadPage(): void {
    this.paginator?.changePage(this.paginator.getPage());
}

createNewDefaultQuote(){
    this._quoteServiceProxy.createDefaultQuote().subscribe({
        next:(res) =>{
            this._router.navigateByUrl(`/app/main/quotes/${res}`);        
        },
        error:(res) =>{
        }
    })
  }


getAllClients(filter = ''): void {
    const payload = new GetClientInput();
    payload.filter = filter;
    payload.maxResultCount = 100;

    this._clientService.getClientsDropdown().subscribe((resp: ClientsDropdownDto[]) => {
        this.clients = resp;
    });
}

onDeleteQuote(quote: IQuoteListDto): void {
    this.message.confirm(this.l('Quote Will be deleted', quote.name), this.l('AreYouSure'), (isConfirmed) => {
        if (isConfirmed) {
            this._quoteServiceProxy.deleteQuote(quote.id).subscribe(() => {
                this.reloadPage();
                this.notify.success(this.l('SuccessfullyDeleted'));
            });
        }
    });
}

onDeleteTemplate(templateId: string): void {
  this._quoteServiceProxy.deleteQuote(templateId).subscribe(() => {
      let deleteTemplateIndex = this.allQuotesTemplates.findIndex(x => x.templateId == templateId);
      this.allQuotesTemplates.splice(deleteTemplateIndex, 1);
      this.notify.success(this.l('SuccessfullyDeleted'));
  });
}

onDuplicateQuote(quote: QuoteListDto): void {
    const payload = new EntityDtoOfGuid({ id: quote.id });

    this._quoteServiceProxy.duplicateQuote(payload).subscribe({
        next: (resp: EntityDtoOfGuid) => {
            this._router.navigateByUrl(`/app/main/quotes/${resp.id}`);
        },
    });
}

onDuplicateTemplateQuote(template: any): void {
    const payload = new EntityDtoOfGuid({ id: template.templateId });

    this._quoteServiceProxy.duplicateQuoteTemplate(payload).subscribe({
        next: (resp: EntityDtoOfGuid) => {
            this._router.navigateByUrl(`/app/main/quotes/${resp.id}`);
        },
    });
}

onUpdateQuoteStatus(item: QuoteStatusDto, quote: QuoteListDto): void {
    const status = this.statuses.find((status) => status.name === item.name);
    const quoteStatus: QuoteStatus = status.id as QuoteStatus;
    const payload = new UpdateStatusIdByQuoteDto({
        id: quote.id,
        quoteStatusId: quoteStatus,
    });
    this._quoteServiceProxy.updateStatusdByQuote(payload).subscribe({
        next: () => { this.reloadPage(), this.notify.success('Status changed successfully')},
    });
}
onGenerateChangeOfOrderQuote(quote: QuoteListDto) {
    this._quoteServiceProxy.generateChangeOfOrderFromQuote(quote.id).subscribe({
        next: (resp: string) => {
            this._router.navigateByUrl(`/app/main/quotes/${resp}`);
        },
    });
}
onGenerateWorkOrderQuote(quote: QuoteListDto) {
    this._quoteServiceProxy.generateWorkOrderFromQuote(quote.id).subscribe({
        next: (resp: string) => {
            this._router.navigateByUrl(`/app/main/quotes/${resp}`);
        },
    });
}

onGenerateQuoteFromTemplate(quote: QuoteListDto){
    this._quoteServiceProxy.generateQuoteTemplateFromQuote(quote.id).subscribe({
        next: (resp: string) => {
            this._router.navigateByUrl(`/app/main/quotes/${resp}`);
        },
    });
}

onQuoteActionClick(item: MenuItem, quote: QuoteListDto): void {
    // quote.showQuoteActions = false;

    switch (item.label) {
        case 'Generate Invoice':
            this.onGenerateInvoice(quote.id);
            break;

        case 'View Invoice':
            this._router.navigateByUrl(`/app/main/invoices/${quote.invoiceId}`);
            break;

        case 'Download Invoice':
            // this.onGenerateInvoice(quote.id);
            break;

        case 'Duplicate Quote':
            this.onDuplicateQuote(quote);
            break;

        case 'Delete Quote':
            this.onDeleteQuote(quote);
            break;

        case 'Generate Material Order':
            this.onGenerateMaterialOrderQuote(quote);
            break;
        case 'Generate Change Of Order':
            this.onGenerateChangeOfOrderQuote(quote);
            break;
        case 'Generate Work Order':
            this.onGenerateWorkOrderQuote(quote)
            break;
        case 'Create Quote Template':
            this.onGenerateQuoteFromTemplate(quote)
            break;
    }
}

onIsShareWithTeamChange(ev: CheckboxChangeEvent, quoteId: string): void {
    const payload = new QuoteChangeIsSharedDto();
    payload.id = quoteId;
    payload.isShared = ev.checked;

    this._quoteServiceProxy.quoteChangeIsShared(payload).subscribe({
        next: () => console.log('Quote isSharedWithTeam status updated...'),
    });
}

onViewQuote(quote: IQuoteListDto): void {
    this.itemsPages = [
        {
            id: '',
            name: 'Template',
            key: QuotePageKey.TEMPLATE,
            enabled: true,
            order: 0,
            showActionMenu: false,
            depId: 'template',
        },
    ];
    this._quoteDetailService.getAllQuoteDetailsByQuoteId(quote.id).subscribe({
        next: (resp) => {
            for (const item of resp) {
                // Remove approval page from exising pages if it is already saved.
                if (item.key === QuoteDetalKey.Signature) {
                    let approvalPageIndex = this.itemsPages.findIndex(x=> x.key == QuotePageKey.APPROVAL);
                    if(approvalPageIndex > -1)
                        this.itemsPages.splice(approvalPageIndex, 1);
                }
                if (item.key === QuoteDetalKey.Template) {
                    let templatePageIndex = this.itemsPages.findIndex(x=> x.key == QuotePageKey.TEMPLATE);
                    if(templatePageIndex > -1)
                        this.itemsPages.splice(templatePageIndex, 1);
                }

                const keyObject = { key: item['key'] as number };
                this.itemsPages.push({
                    id: item['id'],
                    name: item['name'] || 'Page Title',
                    key: keyObject['key'] as QuotePageKey,
                    enabled: item['enabled'],
                    order: item['order'],
                    showActionMenu: false,
                    json: item['json'],
                    depId: item['id'],
                    jsonValue: item['json'] && JSON.parse(item['json']),
                });
            }
            this.itemsPages = this.sortByKey(this.itemsPages, 'order');
            this.itemsPages = this.itemsPages.map((item, index) => ({ ...item, index }));

            this._dialogService.open(CommonLookupModalComponent, {
                width: '100vw',
                height: '100vh',
                showHeader: false,
                styleClass: 'view-pdf-dlg',
                data: {
                    quoteId: quote.id,
                    modeId: 1,
                    pages: this.itemsPages,
                    quote: quote,
                    templateId:quote.templateId,
                    templateInfo:this.templateInfo,
                    client: this.clients.find((client) => client.id === quote.clientId),
                    companyInfo:this.companyInfo

                },
            });
        },
    });
}

sortByKey(array, key) {
    return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];

        return x < y ? -1 : x > y ? 1 : 0;
    });
}

showItem(item: MenuItem, quote: IQuoteListDto): boolean {
    if (item.label === 'Generate Invoice') {
        return (quote.quoteStatus === 'Accepted' || quote.quoteStatus === 'InProgress') && !quote.hasInvoice;
    }
    if (['View Invoice', 'Download Invoice'].includes(item.label)) {
        return quote.quoteStatus === 'Accepted' && quote.hasInvoice;
    }
    // if (quote.isSignedFromClient && item.label === 'Duplicate Quote')
    //     return false;
    // if (quote.isSignedFromClient && item.label === 'Delete Quote')
    //     return false;

    return true;
}

private onGenerateInvoice(id: string): void {
    const payload = new EntityDtoOfGuid({ id });
    this._quoteServiceProxy.createInvoiceFromQuote(payload).subscribe({
        next: (invoiceId: string) => this._router.navigateByUrl(`/app/main/invoices/${invoiceId}`),
    });
}

onGenerateMaterialOrderQuote(quote: QuoteListDto) {
    this._MatrialListServiceServiceeProxy.generateMaterialOrderFromQuote(quote.id).subscribe({
        next: (resp: string) => {
            this._router.navigateByUrl(`/app/main/materialorders/${resp}`);
        },
    });
}


createNewQuoteFromTemplate(id:any){
    const quote = new QuoteListDto();
    quote.id = id;
    this._quoteServiceProxy.createQuoteFromQuoteTemplate().subscribe({
        next: (resp: string) => {
            this.visible = false;
            this._router.navigateByUrl(`/app/main/quotes/${resp}`);
        },
    });
}

getAllTemplatesNames(){
    this._quoteServiceProxy.getQuoteTemplateNames().subscribe((res) => {
        this.allQuotesTemplates =  res.map((template) => {
            if(template.isTemplateActive){
                this.QuoteTemplateSelected = true;
                this.selectedQuoteTemplate = template;
                template['checked'] = true;
            }
            else
             template['checked'] = false;
            return template;
        });

        if(!this.allQuotesTemplates.some(q =>q.checked === true))
        {
           let defaultTemplate =  this.allQuotesTemplates.find(t=>t.templateName === "Default Template");
           defaultTemplate!.checked = true;
           defaultTemplate!.isTemplateActive = true;
           this.QuoteTemplateSelected = true;
           this.selectedQuoteTemplate = defaultTemplate;

        }
    });
}
showQuoteTemplatesPopUp(){
    this._quoteServiceProxy.getQuoteTemplateNames().subscribe((res) => {
        this.allQuotesTemplates =  res.map((template) => {
            if(template.isTemplateActive){
                this.QuoteTemplateSelected = true;
                template['checked'] = true;
            }
            else
             template['checked'] = false;
            return template;
        });
        this.visible = true;
    });
}

handleCheckboxChange(checkedItem:any) {
    if(!this.allQuotesTemplates.some(q =>q.checked === true))
        {
            checkedItem!.isTemplateActive = true;
            this.selectedQuoteTemplate = checkedItem;
            this.QuoteTemplateSelected = true;
            setTimeout(() => {
                checkedItem!.checked = true;
            }, 100);
            return;
        }


    let templateStatus = new UpdateQuoteTemplateStatusDto();
    templateStatus.id = checkedItem.templateId;
    templateStatus.isActive = checkedItem.checked


    this._quoteServiceProxy.updateQuoteTemplateStatus(templateStatus).subscribe((res) => {
        if(!res){
            this.notify.error('Record not found');
            return;
        }
        if(checkedItem.checked){
            this.QuoteTemplateSelected = true;
        }
        else{
            this.QuoteTemplateSelected = false;
        }
        this.allQuotesTemplates.forEach(item => {
            if (item !== checkedItem) {
              item.checked = false;
              item.isTemplateActive = false;
            } else{
                if(checkedItem.checked){
                    item.isTemplateActive = true;
                } else{
                    item.isTemplateActive = false;
                }
            }
          });
        this.selectedQuoteTemplate = checkedItem;
        this.notify.success('Saved Successfully');
    });

}

private getUserBusiness(): void {
    this._businessService.getBusinessByCurrentUser().subscribe({
        next: (resp: BusinessDto) => {
            this.setTemplateInfo(resp);
        },
        error: () => {

        },
    });
}

getCompanyData() {
    this._businessService.getCurrentBusiness().subscribe((x) => {
        this.companyInfo = x;
    });
}


private setTemplateInfo(resp: BusinessDto): void {
    this.templateInfo = {
        address: resp.address,
        email: resp.email,
        web: resp.web,
        phone: resp.phone,
        description1: resp.description1,
        templateColor: resp.primaryColor,
        name: resp.name,
        usePhoneNumer: resp.phone,
        date: new Date()
    };
}
todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];
  isFolderDisabled: boolean = true;
  droptest(event: CdkDragDrop<string[]>) {debugger
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  onDropToFolder(event: CdkDragDrop<any[]>, folder: any) {debugger
    if (event.previousContainer !== event.container) {
      const droppedMedia = event.previousContainer.data[event.previousIndex];
      
      // Remove media from the original container
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
  
      // Call backend API to update the folder
      this.moveMediaToFolder(droppedMedia, folder);
    }
  }

}



import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}