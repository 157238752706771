<div class="d-flex-row text-secondary footer" *ngIf="!transparentBackground"
    [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"
    style="padding: 2mm 25.4mm 2mm 25.4mm; height: 60.4px; font-size: 3.5mm; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;">
    <div class="d-flex-column flex-2">
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-globe"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.web || '-' }}</span>
        </div>
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-location"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.address || '-' }}</span>
        </div>
    </div>
    <div class="d-flex-column flex-2" style="text-align: right !important;">
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-phone"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.phone || '-' }}</span>
        </div>
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-mail"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.email || '-' }}</span>
        </div>
    </div>
</div>

<div class="d-flex-row text-dark footer" *ngIf="transparentBackground"
    style="padding: 2mm 22.4mm 2mm 22.4mm; height: 60.4px; font-size: 3.5mm; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; margin: 0 3mm"
    [ngStyle]="{'background-color': transparentBackground ? '#fff' : transparentBackground, 'border-top': !transparentBackground ? 'none' : '2px solid' + templateInfo?.templateColor + ''}">
    <div class="d-flex-column flex-2"
        style="margin: -2mm 0 -2mm 2.5px; padding: 2.5mm 0 2mm 2mm; border-left: 2px solid #000000" [ngStyle]="{
        'border-left': transparentBackground ? 'none' : '2px solid' + templateInfo?.templateColor + ''
    }">
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-globe"
                [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.web || '-' }}</span>
        </div>
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-location"
                [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.address || '-' }}</span>
        </div>
    </div>
    <div class="d-flex-column flex-2" style="text-align: right !important;">
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-phone"
                [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.phone || '-' }}</span>
        </div>
        <div class="d-flex flex-1 align-items-center">
            <i class="icon icon-mail"
                [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"></i>
            <span class="template-footer-ellipsis">{{ templateInfo?.email || '-' }}</span>
        </div>
    </div>
</div>