<div class="container-fluid edit-quote-container p-7">
    <div cdkDropListGroup class="row">
        <div class="quotes-side-bar-menu col-12 col-lg-2" [ngClass]="{ toggled: !toggle }">
            <div class="main-header">
                <div class="main-header__title" (click)="toggle = !toggle">Folders</div>
                <div class="main-header__desc">
                </div>
                <div class="main-header__action-toggle" (click)="toggle = !toggle">
                    <!-- <app-svg-icon class="rotate-icon" icon="double-arrow" size="24"></app-svg-icon> -->
                </div>
                <div >
                    <div class="example-container">
                        <div *ngFor="let item of folders" cdkDrag>
                            <span 
                              cdkDropList 
                              [cdkDropListData]="folders"  
                              class="btn btn-secondary d-flex align-items-center justify-content-center p-3 m-2"
                              (cdkDropListDropped)="onDropToFolder($event, item)"
                              style="min-width: 150px; border-radius: 8px;">
                              <i class="pi pi-folder" style="font-size: 1.5rem; margin-right: 8px;"></i>
                              {{ item.name }}
                            </span>
                          </div>
                    </div>
                </div>
            </div>
            <div class="add_new_btn">
                <button type="button" data-bs-toggle="modal" data-bs-target="#createFolder"
                    class="btn btn-primary btn-ext btn-primary-ext w-100">
                    Create Folder</button>
            </div>
        </div>
        <div class="flex-fill col-12 col-lg-10 position-relative">
            <div class="row m-2 container-fluid">
                <div [@routerTransition] class="quotes-container container">
                    <div class="c-route-page-container">
                        <div class="p-28">
                            <div class="d-flex search justify-content-between align-items-center">
                                <div>
                                    <h2 class="text-header">Media Library</h2>
                                </div>
                                <div class="d-flex flex-column align-items-center">
                                    <div class="add_btn_btn btn_response" type="button" data-bs-toggle="modal"
                                        data-bs-target="#uploadModal">
                                        <span class="filter-by-date">
                                            <!-- <img src="../../../assets/media/icons/filter.svg" /> -->
                                            <span style="color: #016aff;">Upload</span>
                                            <img src="../../../assets/icons/Chevron.svg" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex gap-5 mb-28">
                            <div class="input-wrapper">
                                <img src="../../../assets/media/icons/uil_search.svg"
                                    class="search-input cursor-pointer" />
                                <input 
                                    name="filterText" autoFocus type="text" class="input-wrapper-inner"
                                    placeholder="Search here" />
                                <img *ngIf="filterText" src="../../../assets/icons/close.svg"
                                     class="clear-search ms-3 w-15px me-3 cursor-pointer" />
                            </div>
                            <div class="d-flex align-items-center gap-4">
                                <div class="add_btn_btn btn_response" type="button">
                                    <span class="cc-material-symbols-outlined">
                                        <span style="color: #016aff;">Folders</span>
                                        <img src="../../../assets/icons/Chevron.svg" />
                                    </span>
                                </div>
                                <div class="add_btn_btn btn_response" type="button" >
                                    <span class="cc-material-symbols-outlined">
                                        <span style="color: #016aff;">Images</span>
                                        <img src="../../../assets/icons/Chevron.svg" />
                                    </span>
                                </div>
                                <div class="add_btn_btn btn_response" type="button">
                                    <span class="cc-material-symbols-outlined">
                                        <span style="color: #016aff;">Videos</span>
                                        <img src="../../../assets/icons/Chevron.svg" />
                                    </span>
                                </div>
                                <div class="add_btn_btn btn_response" type="button">
                                    <span class="filter-by-document ">
                                        <span style="color: #016aff;">Documents</span>
                                        <img src="../../../assets/icons/Chevron.svg" />
                                    </span>
                                </div>
                                <div class="add_btn_btn btn_response" type="button">
                                    <span class="filter-by-date">
                                        <span style="color: #016aff;">Filter by date</span>
                                        <img src="../../../assets/icons/Chevron.svg" />
                                    </span>
                                </div>
                                <div class="add_btn_btn btn_response">
                                    <span class="c-material-symbols-outlined" (click)="selectedView = 'grid'"
                                        [class.active]="selectedView === 'grid'">
                                        <img src="../../../assets/media/icons/healthicons_ui-menu-grid-outline.svg"
                                            class="theme-icon h-16" />
                                        <img src="../../../assets/media/icons/grid_white.svg" class="white-icon h-12" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="grid-view" *ngIf="selectedView === 'grid'">
                                <div class="container mt-4">
                                    <h3>Assets (241)</h3>
                                    <div class="row example-container" >
                                        <div style="display: flex;
                                        gap: 10px;" cdkDropList [cdkDropListData]="mediaLibraries"
                                            (cdkDropListDropped)="droptest($event)">
                                            <div *ngFor="let media of mediaLibraries" cdkDrag>
                                                <div class="quote-card h-100 d-flex">
                                                    <div class="image-part">
                                                        <div class="img-container">
                                                            <p-checkbox [(ngModel)]="checked" id="media.id"
                                                                name="media.fileName" binary="true"
                                                                class="circle-checkbox" variant="filled">
                                                            </p-checkbox>
                                                          
                                                            <!-- Conditional rendering for PDFs, Images, and Videos -->
                                                            <ng-container *ngIf="media.fileName?.endsWith('.pdf'); else mediaPreview">
                                                              <!-- PDF Preview using pdf-viewer -->
                                                              <pdf-viewer [src]="media.blobUrl" [render-text]="true" style="display: block;"></pdf-viewer>
                                                            </ng-container>
                                                          
                                                            <!-- Image or Video Preview -->
                                                            <ng-template #mediaPreview>
                                                              <ng-container *ngIf="media.fileName?.endsWith('.mp4') || media.fileName?.endsWith('.webm') || media.fileName?.endsWith('.ogg')">
                                                                <!-- Video Preview -->
                                                                <video [src]="media.blobUrl" controls width="200" height="200">
                                                                  Your browser does not support the video tag.
                                                                </video>
                                                              </ng-container>
                                                              <ng-container *ngIf="!(media.fileName?.endsWith('.pdf') || media.fileName?.endsWith('.mp4') || media.fileName?.endsWith('.webm') || media.fileName?.endsWith('.ogg'))">
                                                                <!-- Image Preview for non-PDF/Video files -->
                                                                <img *ngIf="media.fileName" class="quote-img" [src]="media.blobUrl" alt="Media Image" />
                                                              </ng-container>
                                                            </ng-template>
                                                          </div>                                                          
                                                        <div
                                                            class="card-body d-flex align-items-center justify-content-between">
                                                            <p class=" mb-0">{{ media.fileName }}</p>
                                                            <div>
                                                                <p-menu [model]="menuItems" [appendTo]="'body'"
                                                                    popup="true" #menu></p-menu>
                                                                <button type="button"
                                                                    class="btn btn-outline-secondary btn-sm"
                                                                    (click)="menu.toggle($event)">
                                                                    ...
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered custom-dialog">
            <div class="modal-content" style="width: 137%;">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form [formGroup]="filterForm" class="container mt-1" (ngSubmit)="applyFilters()">
                    <div class="modal-body">
                        <div class="d-flex align-items-center gap-4">
                            <div class="card">
                                <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true"
                                    (onChange)="onTabChange($event)">
                                    <p-tabPanel *ngFor="let tab of scrollableTabs" [header]="tab.title">
                                        <ng-template pTemplate="header">
                                            <div style="text-align: center;">
                                                <img [src]="tab.icon" alt="{{ tab.title }}"
                                                    style="width: 26px; align-items: center;" />
                                                <br />
                                                <div
                                                    style="width: 83px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                                    {{ tab.title }}
                                                </div>
                                            </div>
                                        </ng-template>

                                        <ng-template pTemplate="body">
                                            <ng-container *ngIf="activeIndex === scrollableTabs.indexOf(tab)">
                                                <div *ngIf="activeIndex === 0" class="upload-container">
                                                    <label for="file-upload" class="custom-file-upload">
                                                        <i class="fa fa-cloud-upload"></i> Browse
                                                    </label>
                                                    <input id="file-upload" type="file"
                                                        (change)="onFileSelected($event)" />

                                                    <button class="upload-button" (click)="onUpload()"
                                                        data-bs-dismiss="modal" [disabled]="!selectedFile">
                                                        Upload
                                                    </button>
                                                </div>
                                                <div *ngIf="activeIndex === 1">
                                                    Content for {{ tab.title }}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </p-tabPanel>
                                </p-tabView>
                            </div>
                        </div>
                        <div class="modal-footer">

                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
    <div class="modal fade" id="createFolder" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered custom-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Folder</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form class="container mt-1">
                    <div class="modal-body">
                        <div class="row">
                            <input type="text" name="folder" [(ngModel)]="folder.name"
                                placeholder="Enter the folder name" id="folder" class="custom-date-range" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex gap-2">
                            <div class="add_btn_btn btn_response" data-bs-dismiss="modal" type="button">
                                <span class="cc-material-symbols-outlined">
                                    <span style="color: #016aff;">Cancel</span>
                                </span>
                            </div>
                            <button class="btn btn-primary btn-ext btn-primary-ext w-100" (click)="saveFolder()"
                                data-bs-dismiss="modal">Save Changes</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered custom-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form [formGroup]="filterForm" class="container mt-1" (ngSubmit)="applyFilters()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="d-flex justify-content-between mb-6">
                                <div class="filter-name">Client</div>
                                <div class="clear-btn" (click)="clearClientFilter()">Clear</div>
                            </div>
                            <p-dropdown class="full-width-dropdown compact-field" [options]="clients" [filter]="true"
                                filterBy="name" formControlName="client" optionLabel="name"
                                [showClear]="true"></p-dropdown>
                        </div>
                        <div class="row">
                            <div class="d-flex justify-content-between mb-6">
                                <div class="filter-name">Date</div>
                                <div class="clear-btn" (click)="clearDateFilter()">Clear</div>
                            </div>
                            <input type="text" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                [alwaysShowCalendars]="false" [linkedCalendars]="false" [ranges]="ranges"
                                formControlName="dateRange" class="custom-date-range" />
                        </div>
                        <div class="row">
                            <div class="flex flex-column gap-2">
                                <div class="d-flex justify-content-between mb-6">
                                    <div class="filter-name">Statuses</div>
                                    <div class="clear-btn" (click)="clearDateFilter()">Clear</div>
                                </div>
                                <p-multiSelect class="compact-field" [options]="statuses" formControlName="statuses"
                                    optionLabel="name" [filter]="false" [style]="{ width: '100%' }"></p-multiSelect>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary w-100" data-bs-dismiss="modal">Apply
                            Filters</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>