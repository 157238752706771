<html
    [ngStyle]="{
        'transform': isScale ? 'scale(0.315)' : 'none',
        'transform-origin': isScale ? 'top left' : 'none',
    }">
<head>
    <style>
        body {
            font-family: 'qcfont';
            position: relative;
        }

        .footer {
            font-size: 7mm;
            padding: 4mm 0;
            justify-content: space-around;
            font-size: 7mm;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    </style>
</head>

<body class="d-flex d-flex-column" style="font-size: 13px;">
    <div class="container flex-1 d-flex">
        <div class="d-flex-column flex-1">
            <div class="d-flex-column flex-2" style="margin-top:10px; margin-bottom:20px;">
                <div class="d-flex flex-2 m-l-254">
                    <div class="b-logo w-50" [ngStyle]="{
                    'background-image': selectedImg?.uniqueFileName ? 'url(' + selectedImg?.imgSrc + ')' :
                    'var(--b-image-url)', 
                    'background-position': 'left',
                    'background-size': 'contain'
                }"></div>
                </div>
            </div>
            <div class="d-flex-column flex-7">
                <div class="flex-2 m-l-254 text-secondary"
                    [ngStyle]="{'background-color': templateInfo?.templateColor ? templateInfo?.templateColor : 'bg-primary'}"
                    style="margin-right: 30mm;margin-top:40mm;margin-bottom:40mm;">
                    <div style="font-size: 17mm; color:white; padding:10mm; font-weight: bold; line-height: 1.42; -webkit-box-orient: vertical; display: -webkit-box; max-width: 100%; height: 330px; overflow-wrap: break-word; -webkit-line-clamp: 3; text-overflow: ellipsis; overflow: hidden; box-sizing: border-box; word-break: break-word;">{{ templateInfo?.description1 }}</div>
                </div>
            </div>
            <div class="d-flex-row flex-2 m-l-254 m-r-254" style="padding: 5mm 0mm; color:black; font-size: 6mm;">
                <div class="d-flex-column flex-2">
                    <div class="d-flex-row" style="padding-bottom: 2mm;">
                        <span class="flex-10" style="font-family: qc-Montserrat-ExtraBold; text-align: left;">Prepared for:</span>
                    </div>
                    <div style="font-family: qc-OpenSans-Regular; text-align: left;" class="paragpraph-ellipsis">
                        Client Name
                    </div>
                    <div style="font-family: qc-OpenSans-Regular; text-align: left;" class="paragpraph-ellipsis">
                        Address
                    </div>
                    <div style="font-family: qc-OpenSans-Regular; text-align: left;" class="address-ellipsis">
                        City, State, Zip Code
                    </div>
                </div>
                <div class="d-flex-column flex-2" style="text-align:left;">
                    <div class="d-flex-row" style="padding-bottom: 2mm;">
                        <span class="flex-10" style="font-family: qc-Montserrat-ExtraBold;">Presendted by:</span>
                    </div>
                    <div style="font-family: qc-OpenSans-Regular; text-align: left;" class="paragpraph-ellipsis">
                        {{ templateInfo?.userName }}
                    </div>
                    <div style="font-family: qc-OpenSans-Regular; text-align: left;" class="paragpraph-ellipsis">
                        {{ templateInfo?.usePhoneNumer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-template-footer [templateInfo]="templateInfo"></app-template-footer>
</body>

</html>