<div class="d-flex justify-content-center align-items-center flex-column w-100 h-100  upload-image-section" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
    <div class="w-100 align-items-center d-flex flex-column gap-3 justify-content-center">
        <img src="/assets/icons/add-image.svg" alt="">
        <div class="position-relative w-100 d-flex justify-content-center">
            <button (click)="showUpload = !showUpload" class="btn btn-primary btn-ext btn-primary-ext">Upload Image &nbsp;<i class="pi pi-chevron-down iconz fs-12"></i> </button>
            <ul (mouseleave)="showUpload = !showUpload" class="drop-upload"*ngIf="showUpload">
                <li (click)="openMediaLibraryDialog()"> <i class="pi pi-image iconz"></i> Upload from Medial Gallery</li>
                <li (click)="openWidget();"> <i class="pi pi-upload iconz"></i>Upload from computer</li>
                <input hidden type="file" #uploadFilesField [accept]="allowedFileTypes" (change)="fileBrowseHandler(uploadFilesField)" />
            </ul>                                    
        </div>
    </div>
    <input hidden type="file" #uploadFilesField [accept]="allowedFileTypes" (change)="fileBrowseHandler(uploadFilesField)" />
</div>