import { Component, Input } from '@angular/core';
import { IQuotePage, TemplateViewTypesEnum } from '@app/main/quotes/models';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-image-text-list-new',
    templateUrl: './image-text-list-new.component.html',
    styleUrls: ['./image-text-list-new.component.scss'],
})
export class ImageTextListNewComponent {
    @Input() isTemplateType: TemplateViewTypesEnum = TemplateViewTypesEnum.StaticTemplate;
    templateTypes = TemplateViewTypesEnum;
    @Input() templateInfo;

    @Input() data: IQuotePage;
    @Input() pageNo: number;
    baseUrl = environment.remoteServiceBaseUrl;
    result:any


    ngOnInit(){
        this.result = this.createConsecutivePairs(this.data.jsonValue.ImageTextList);
    }


    createConsecutivePairs(objects) {
        let pairs = [];
        let n = objects.length;
        for (let i = 0; i < n; i += 2) {
            let pair = objects.slice(i, i + 2);
            pairs.push(pair);
        }
        return pairs;
    }
}
