import { Injector, Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './theme60-layout.component.html',
    selector: 'theme60-layout',
    styleUrls: ['./theme60-layout.component.scss'],
    animations: [appModuleAnimation()],
})
export class Theme60LayoutComponent extends ThemesLayoutBaseComponent implements OnInit, OnDestroy {
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    containsQuoteRoute:any = false;
    containsInvoiceRoute:any = false;
    private routerSubscription: Subscription;
    constructor(
        injector: Injector,
        private _router: Router,
        _dateTimeService: DateTimeService,
        private location: Location,
        @Inject(DOCUMENT) private document: Document) 
    {
        super(injector, _dateTimeService);
        this.containsQuoteRoute = this.location.path().includes('quotes');
        this.containsInvoiceRoute = this.location.path().includes('invoices');
        this.routerSubscription = this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.containsQuoteRoute = this.location.path().includes('quotes');
                this.containsInvoiceRoute = this.location.path().includes('invoices');
            }
        });
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        if (this.currentTheme.baseSettings.menu.defaultMinimizedAside) {
            this.document.body.setAttribute('data-kt-aside-minimize', 'on');
        }
        // if(!this.appSession.tenant.edition){
        //     this._router.navigate(['/account/select-edition'])
        // }
    }
    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
    getMobileMenuSkin(): string {
        return this.appSession.theme.baseSettings.layout.darkMode ? "dark" : "light";
    }
}
