import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {
  private baseUrl = environment.remoteServiceBaseUrl;;

  constructor(private http: HttpClient) {}

  generateQuotePdf(quoteId: string, isChanged: boolean = false) {
    let url_ = `${this.baseUrl}/home/GeneratePDf?QuoteId=${quoteId}&isChanged=${isChanged}`;
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get(url_);
  }

  generateInvoicePdf(invoiceId: string) {
    let url_ = `${this.baseUrl}/home/generateinvoicepdf?invoiceid=${invoiceId}`;
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get(url_);
  }

  viewPdf(endPoint: string, fileName: string) {
    let url_ = `${this.baseUrl}/home/${endPoint}?filename=${fileName}`;
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get(url_);
  }

  viewEmailedQuotePdf(key: string) {
    let url_ = `${this.baseUrl}/Email/viewPdf?key=${key}`;
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get(url_);
  }

  uploadQuoteImageFileForSignClient(payload: any) {
    let url_ = `${this.baseUrl}/home/UploadQuoteImageFileForSignClient`;
    url_ = url_.replace(/[?&]$/, '');

    return this.http.post(url_, payload);
  }
}
