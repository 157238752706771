import { Component, Injector, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-template4',
  templateUrl: './template4.component.html',
  styleUrls: ['./template4.component.css']
})
export class Template4Component extends AppComponentBase {
  @Input() businessDetails;
  @Input() selectedImg;
  @Input() selectedCoverImage;
  @Input() isScale = false;
  @Input() templateInfo;
  @Input() selectedTemplate;
  
  @ViewChild('templatePickerModal', { static: true }) modal: ModalDirective;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  show(): void {
    this.modal.show();
  }

  save() {
    this.close();
  }

  close() {
    this.modal.hide();
  }
}
